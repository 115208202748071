import React from 'react';
import { NumericFormat } from 'react-number-format';

const NumberFormatInput = React.forwardRef(
  function NumberFormatInput(props, ref) {
    const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  )
});

export default NumberFormatInput;
