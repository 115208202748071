import React, {
  useEffect,
  useReducer,
  useMemo
} from "react";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { SnackbarContentWrapper, SearchBox } from "../../components";
import { getCompanies } from "../../services/companyAPI";
import {ButtonLink} from "../../components";
import TableCompany from "./TableCompany";
import styles from "./company.module.scss";

  let initialState = {
    companies:[],
    isLoading: false,
    isUpdating: false,
    errorHttp: "",
    msg: "",
    variant: "",
    searchText: ""
  };
  let filteredList = [];
  function reducer(state, action) {
    switch (action.type) {
      case "FETCH_COMPANIES_SUCCESS":
        return {
          ...state,
          companies: action.payload,
          isLoading: false,
          errorHttp: ""
        }
      case "FETCH_COMPANIES_REQUEST":
        return {
          ...state,
          isLoading: true,
          errorHttp: ""
        };
      case "FETCH_COMPANIES_FAILURE":
        return {
          ...state,
          isLoading: false,
          msg: "Hubo un error al intentar cargar las compañías.",
          variant: "error"
        }
      case "SEARCH_TEXT":
        return {
          ...state,
          searchText: action.payload
        }
      default:
        return state;
    }
  }

  function List() {
    const [state, dispatch] = useReducer(reducer, initialState);

    async function fillTable(text, isActive) {
      try {
        dispatch({type: 'FETCH_COMPANIES_REQUEST'});
        const {body: companiesFetched } = await getCompanies(text, isActive);
       if (companiesFetched.success) {
          dispatch({
            type: "FETCH_COMPANIES_SUCCESS",
            payload: companiesFetched.data
          });
        }        
      } catch(err) {
        dispatch({type: "FETCH_COMPANIES_FAILURE"});
      } 
    }

    function getByName(text) {
      dispatch({type: 'SEARCH_TEXT', payload: text})
    }
   
    useEffect(()=>{
        fillTable();
    },[])

    useMemo(() => {
      if (!!state.searchText) {
        filteredList = state.companies.filter((compania) => {
          let companiaFound = null;
          let word = new RegExp(`^${state.searchText.toLocaleLowerCase()}`, "gi");
          if (word.test(compania.name.toLocaleLowerCase())) {
            companiaFound = compania;
          }
          return companiaFound;
        });
      } else {
        filteredList = state.companies;
      }
    }, [state.companies.length, state.searchText]);

    return (
        <>
          <div className={styles.btnNewContainer}>
          <ButtonLink to='/companias/nuevo'>
            <AddCircleOutlineOutlinedIcon/>Nueva compañia  
          </ButtonLink>
          </div>
          {!!state.msg && (
            <SnackbarContentWrapper variant={state.variant} message={state.msg} open={true} />
          )}
           <div className={styles.searchBarContainer}>
              <SearchBox getByName={getByName} placeholder="Nombre de Compañía" extraFilters={false} />
           </div>
           <TableCompany loading={state.isLoading} companies={filteredList}/>
        </>
    )
  }

  export default List