import { isEmpty, isEmail } from './validators'
import floor from "lodash.floor";

export const scrollTop = function (parent = window) {
    parent.scrollTo(0, 0)
  }

  export function transformText(text) {
    let textTransform = text.split(' ').reduce((acc, n) => {
      if (!isEmpty(n)) {
        let names = n;
        names = names.trim().toLowerCase();
        names = names[0].toUpperCase() + names.substring(1, names.length)
        acc.push(names);
        return acc;
      }
      acc.push(n);
      return acc;
    },[]).join(' ');
    
    return textTransform.trim()
 }

 export function formatCurrency(amount) {
   return amount != null && amount !== "" ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : amount;
 }

 export function calculateAmount(firstItemCurrency, selectedCurrencyType, currencyRate) {
  return function(price) {
    let newAmount = 0;
    if(typeof firstItemCurrency === 'undefined' || 
      firstItemCurrency === selectedCurrencyType) {
      newAmount = floor(price,2)
    } else if (selectedCurrencyType === "USD") {
      newAmount = floor(price/currencyRate,2)
    } else {
      newAmount = floor(price * currencyRate,2)
    }

    return newAmount;
  }
}

export function validateForm(form, inputsName) {
  let isValid = true;
  let validatedState = inputsName.reduce((acc, crr) => {
    acc[crr] = true;

    if (crr === 'email' && !isEmail(acc[crr])) {
      acc[crr] = false;
      isValid = false;
    } else if (!form[crr]) {
      acc[crr] = false;
      isValid = false;
    }
    return acc;
  }, {});
  return { isValid, validatedState };
}

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

export function getMonthName(monthNumber) {
  return months[monthNumber]
}