import React, { useState } from 'react';
import { 
  Container, 
  Select, 
  MenuItem, 
  Modal, 
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from '@mui/material';
import API from "../../config/api";
import { API_URL } from "../../config/environment";
import { InvoiceSummary, InvoiceDetail, MembersUpdated } from "../../components/Reports";
import styles from "./reports.module.scss";

const component = (onClose, onHandleData) => ({
  summary: <InvoiceSummary onClose={onClose} onGenerate={onHandleData} />,
  detail: <InvoiceDetail onClose={onClose} onGenerate={onHandleData}/>,
  members_updated: <MembersUpdated onClose={onClose} onGenerate={onHandleData}/>
});

const REPORT_METADATA = {
  membersByZone: {
    url: `${API_URL}member/members-by-zone`,
    canConsult: false,
    canPrint: true,
    datesForm: false,
  },
  listOfMembers: {
    url: `${API_URL}member/members-list-excel`,
    canConsult: false,
    canPrint: true,
    datesForm: false,
  },
  listMiembrosIncompleto: {
    url: `${API_URL}member/members_incomplete`,
    canConsult: false,
    canPrint: true,
    datesForm: false,
  },
  members_updated: {
    url: `${API_URL}member/members_updated`,
    canConsult: true,
    canPrint: false,
    title: "Miembros actualizados",
    datesForm: true,
  },
  summary: {
    datesForm: true,
    canPrint: true,
  },
  detail: {
    datesForm: true,
    canPrint: true,
  },
  accountMovement: {
    url: `${API_URL}accounting/movement`,
    datesForm: false,
    canConsult: false,
    canPrint: true,
  },
  totalByGroupService: {
    datesForm: true,
    canPrint: true,
  },
  tallerParticipant: {
    // The T1-11-2024-tiva_y_ventiliacion should be a Drop down to be send as a param
    url: `${API_URL}taller_for_participant/participant_list/T1-11-2024-tiva_y_ventiliacion`,
    datesForm: false,
    canConsult: false,
    canPrint: true,
  },
}

const Reports = () => {
  const [reportType, setReportType] = useState("reports");
  const [reportData, setReportData] = useState({
    total: 0,
    data: [],
    dateIn: '',
    dateFn: ''
  });
  const [openDialog, setOpenDialog] = useState(false);
  
  function downloadFile(filePath) {
    var link = document.createElement('a');
    // link.setAttribute
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
    //document.body.removeChild(a);
  }

  const handleClose = () => {
    setOpenDialog(false);
  }

  const downloadReport = () => {
    if(reportType !== "reports" && REPORT_METADATA[reportType].canPrint) {
      window.open(REPORT_METADATA[reportType].url, "download");
    }
  }

  const onHandleChange = (event) => {
    const { value } = event.target;

    setReportType(value);
    setReportData({
      total: 0,
      data: [],
      dateIn: '',
      dateFn: ''
    });
  }

  const onHandleData = async (dateStart, dateEnd) => {
    const data = await API.get(`${REPORT_METADATA[reportType].url}?dateIn=${dateStart}&dateFn=${dateEnd}`);

     setReportData({
      total: data.body.count,
      data: data.body.data,
      dateIn: dateStart,
      dateFn: dateEnd
     });
     setOpenDialog(false);
 }

 const onHandleConsult = () => {
    if (REPORT_METADATA[reportType].datesForm) {
      setOpenDialog(true);
    }
 }

 const onHandlePrint = () => {
  if (REPORT_METADATA[reportType].datesForm) {
    setOpenDialog(true);
  } else {
    downloadReport();
  }
 }

  return (<Container>
    <h3>Reportes de sistema</h3>
    <div>
      <div className={styles.container}>
        <label htmlFor="selectReport">Elija un reporte</label>
        <Select
          id="selectReport"
          value={reportType}
          label="Elija un reporte"
          onChange={onHandleChange}
        >
          <MenuItem value="reports">Reportes</MenuItem>
          <MenuItem value="membersByZone">Miembros por zona</MenuItem>
          <MenuItem value="listOfMembers">Listado de miembros</MenuItem>
          <MenuItem value="listMiembrosIncompleto">Miembros incompleto</MenuItem>
          <MenuItem value="summary">Reporte de caja resumido</MenuItem>
          <MenuItem value="detail">Reporte de caja detallado</MenuItem>
          <MenuItem value="members_updated">Miembros actualizados</MenuItem>
          <MenuItem value="accountMovement">Movimiento de cuentas</MenuItem>
          <MenuItem value="tallerParticipant">Participantes por taller</MenuItem>
        </Select>
      </div>
      <div className={styles.btnContainer}>
        <Button 
          variant="contained" 
          disabled={!REPORT_METADATA[reportType]?.canConsult}
          onClick={onHandleConsult}
        >
            Consultar
        </Button>
        <Button 
          onClick={onHandlePrint} 
          variant="contained"
          disabled={!REPORT_METADATA[reportType]?.canPrint}
        >
          Generar archivo
        </Button>
      </div>
    </div>

    <Modal
      open={openDialog}
      onClose={handleClose}
      className="report_modal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {component(handleClose, onHandleData)[reportType] || <></>}
    </Modal>
    {
      reportData.data.length ?
       <div className={styles.consultContainer}>
          <h4 className={styles.title}>Consuleta de {REPORT_METADATA[reportType]?.title}</h4>
          <div className={styles.datesContainer}>
            <span className={styles.date}>{reportData.dateIn}</span>
            <span>/---\</span>
            <span className={styles.date}>{reportData.dateFn}</span>
          </div>
          <div className={styles.count}>
            <span>Total de actualizados:</span><span>{reportData.total}</span>
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Cedula</TableCell>
                  <TableCell align="left">Nombres</TableCell>
                  <TableCell align="left">Apellidos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {reportData.data.map((row, index) => (
                <TableRow
                  key={`${index}-${row.identifier}`}
                >
                    <TableCell padding="checkbox" align="left">{index || 0}</TableCell>
                  <TableCell padding="checkbox">
                    {row.identifier}
                  </TableCell>
                  <TableCell padding="checkbox" align="left">{row.names}</TableCell>
                  <TableCell padding="checkbox" align="left">{row.familyName}</TableCell>
                </TableRow>
                ))
              }
              </TableBody>
            </Table>
          </TableContainer>
          {
            console.log("DATA***", reportData)
          }
       </div>
    : null
    }
  </Container>)
}

export default Reports;
