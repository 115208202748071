import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Menu from "../components/Menu";
import styles from './layout.module.scss';

const drawerWidth = 240;

const AuthLayout = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleForMenuCollapse = (option) => {
    setOpenMenu(option)
  }

  return (
    <Box className={styles.container} sx={{ display: "flex" }}>
      <Box
        className={styles.menuContainer}
        component="nav"
        sx={{ width: { sm: openMenu ? drawerWidth : 0 }, flexShrink: { sm: 0 } }}
      >
        <Menu openMenu={openMenu} handleForMenuCollapse={handleForMenuCollapse}/>
      </Box>
      <Box
        className={styles.contentContainer}
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: 10,
          width: { sm: `calc(100% - ${openMenu ? drawerWidth : 0}px)` },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default AuthLayout;
