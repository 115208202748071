import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import styles from "./documentAdded.module.scss";

function DocumentAdded({
  checkDocumentItem,
  generalDoctorTitle,
  degreeInAnesthesiology,
  curriculumVitae,
  exequatur,
  medicalCollegeCardAndCertification,
  letterOfRecommendationAnesthesiologists,
  presentScientificTopic,
  two2x2photos,
  inscription,
}) {
  return (
    <fieldset className={styles.documentContainer}>
      <legend>Documentos</legend>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={generalDoctorTitle}
              onChange={checkDocumentItem}
              name="generalDoctorTitle"
              color="primary"
            />
          }
          label="Título de Médico General"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={degreeInAnesthesiology}
              onChange={checkDocumentItem}
              name="degreeInAnesthesiology"
              color="primary"
            />
          }
          label="Título de Anestesiología"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={curriculumVitae}
              onChange={checkDocumentItem}
              name="curriculumVitae"
              color="primary"
            />
          }
          label="Curriculum Vitae"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={exequatur}
              onChange={checkDocumentItem}
              name="exequatur"
              color="primary"
            />
          }
          label="Exequátur"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={medicalCollegeCardAndCertification}
              onChange={checkDocumentItem}
              name="medicalCollegeCardAndCertification"
              color="primary"
            />
          }
          label="Carnet Colegio Médico y certificacíon"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={letterOfRecommendationAnesthesiologists}
              onChange={checkDocumentItem}
              name="letterOfRecommendationAnesthesiologists"
              color="primary"
            />
          }
          label="Carta de recomendación Anestesiólogos"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={presentScientificTopic}
              onChange={checkDocumentItem}
              name="presentScientificTopic"
              color="primary"
            />
          }
          label="Presentar Tema Cientifico"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={two2x2photos}
              onChange={checkDocumentItem}
              name="two2x2photos"
              color="primary"
            />
          }
          label="Dos fotos 2x2"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={inscription}
              onChange={checkDocumentItem}
              name="inscription"
              color="primary"
            />
          }
          label="Inscripción"
        />
      </FormGroup>
    </fieldset>
  );
}

export default DocumentAdded;
