import React from 'react';
import { ButtonLink } from "../..";
import {
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import clsx from 'clsx';
import styles from "./LastUpdated.module.scss";

function LastUpdated (props) {
  const { member } = props;
    return (
       <div className={styles.last_updated__container}>
         <Typography variant='subtitle2'>Ultima Registro Trabajado</Typography>
         <Table size="small" aria-label="a dense table">
           <TableBody className={clsx({[styles.aspiringLine]: member.isAspiring})}>
             <TableRow>
               <TableCell component="th" scope="row">
               {member.isAspiring ? <span className={styles.aspiringTag}>A</span> : null }{member.identifier}
               </TableCell>
               <TableCell>
                 {member.names}
               </TableCell>
               <TableCell>
                 {member.familyName}
               </TableCell>
               <TableCell>
                 {member.status}{member.isUnCompleted ? "(INCOMPLETO)" : ""}
               </TableCell>
               <TableCell>
                 <ButtonLink
                  to={member.isAspiring ? `/miembros/aspirante/${member.identifier}` : `/miembros/${member.identifier}`}
                  state={{ edit: true, member: member }}
                 >
                  <Icon color="action">create</Icon>
                 </ButtonLink>
               </TableCell>
             </TableRow>
           </TableBody>
         </Table>
        </div>
    )
}

export default LastUpdated