import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Icon,
  TablePagination,
  TableContainer  
} from "@mui/material";
import { ButtonLink } from "../..";
import styles from "./tableMember.module.scss";

TableMember.propTypes = {
  stageDeleteMember: PropTypes.func,
  allowDelete: PropTypes.bool,
  totalMember: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  page: PropTypes.number,
  handleChangePage: PropTypes.func
};

TableMember.defaultProps = {
  allowDelete: true,
  totalMember: 0
}

function TableMember({
  membersArray,
  loading,
  handleChangePage,
  page,
  stageDeleteMember,
  allowDelete,
  totalMember
}) {
  let numberRow = page * 10;
  
  return (
    <>
      {loading ? (
        <>
          <CircularProgress />
          <p>Cargando...</p>
        </>
      ) : (
        <React.Fragment>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">No.</TableCell>
                <TableCell align="left">Cédula</TableCell>
                <TableCell align="left">Nombres</TableCell>
                <TableCell align="left">Apellidos</TableCell>
                <TableCell align="left">Código</TableCell>
                <TableCell align="left">Membresias</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Opción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {membersArray.map((row, index) => (
                <TableRow
                  key={`${index}-${row.identifier}`}
                  className={
                    row.isUnCompleted
                      ? styles.row_uncompleted
                      : row.isDeceased
                      ? styles.row_deceased
                      : "row_normal"
                  }
                >
                  <TableCell padding="checkbox" align="left">{++numberRow || 0}</TableCell>
                  <TableCell padding="checkbox">
                    {row.isAspiring ? <span className={styles.aspiringTag}>A</span> : null }{row.identifier}
                  </TableCell>
                  <TableCell padding="checkbox" align="left">{row.names}</TableCell>
                  <TableCell padding="checkbox" align="left">{row.familyName}</TableCell>
                  <TableCell padding="checkbox" align="left">{row.codeSchool}</TableCell>
                  <TableCell padding="checkbox" align="left">{row.membershipToPay}</TableCell>
                  <TableCell padding="checkbox" align="left">{`${row.status}${row.isUnCompleted ? "(INCOMPLETO)" : ""}`}</TableCell>
                  <TableCell padding="checkbox" align="left">
                    <ButtonLink
                      to={`/miembros/${row.identifier}`}
                      state={{ edit: true, member: row }}
                    >
                      <Icon color="action">create</Icon>
                    </ButtonLink>
                    {
                      allowDelete ?
                        <Icon className={styles.table_invoice} onClick={() => stageDeleteMember(row)}>
                          delete_forever
                        </Icon> 
                        : null
                    }               
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalMember}
          rowsPerPage={10}
          page={page}
          //onChangePage={handleChangePage}
          onPageChange={handleChangePage}
        />
        </React.Fragment>
      )}
    </>
  );
}

export default TableMember;
