import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRouter from "./WrapperRouter/PrivateRouter";
//import FormRouter from "./FormRouters";
//import InvoiceRouter from "./InvoiceRouter";
import AuthLayout from "../layouts/AuthLayout";
import {
  MemberForm,
  MembersList,
  ChargeForm,
  Messaging,
  LogIn,
  NotFound,
  ServicesList,
  ServicesForm,
  Companies,
  Invoices,
  CatalogoCuentas,
  MembersDeceaseds,
  DocumentsList,
  CompanyForm,
  Reports,
  Aspiring,
  TaxReceiptSequence,
  CommercialProviderForm,
  CommercialProviderList,
  DepositForm,
  DepositList,
  InvoicePenddingList,
  InvoicePenddingForm,
  PartialInvoiceList
} from "../pages";
import Company from "../pages/Company/Form";
import CompanyInvoice from "../pages/ChargeManagement/containers/Company";
import MemberInvoice from "../pages/ChargeManagement/containers/Member";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LogIn />} path="/" />
        <Route element={<LogIn />} path="/login" />
        <Route element={<AuthLayout />}>
          <Route element={<PrivateRouter />}>
            <Route path='deposito'>
              <Route index element={<DepositList/>}/>
              <Route path='nuevo' element={<DepositForm/>}/>
            </Route>
            <Route path='proveedor'>
              <Route index element={<CommercialProviderList/>}/>
              <Route path="nuevo" element={<CommercialProviderForm/>} />
              <Route path=":id" element={<CommercialProviderForm/>} />
            </Route>
            <Route path='comprobantes' element={<TaxReceiptSequence/>} />
            <Route path="servicios">
              <Route index element={<ServicesList />} />
              <Route path="servicios/nuevo" element={<ServicesForm />} />
              <Route path=":servicioId" element={<ServicesForm />} />
            </Route>
            <Route path="companias">
              <Route index element={<Companies />} />
              <Route path="nuevo" element={<CompanyForm/>} />
              <Route path=":companyId" element={<CompanyForm/>} />
            </Route>
            <Route path="formulario">
              <Route path="compania/nuevo" element={<Company />} />
              <Route path="compania/:id" element={<Company />} />
            </Route>
            <Route path="/recibos">
              {/*Estilo por trabajar*/}
              <Route index element={<Invoices />} />
              <Route path="compania" element={<CompanyInvoice />} />
              <Route path="miembro" element={<MemberInvoice />} />
              <Route path="credito">
                <Route index element={<InvoicePenddingList />} />
                <Route path="nuevo/:invoiceId" element={<InvoicePenddingForm />} />
                <Route path="pagados" element={<PartialInvoiceList/>}/>
              </Route>
            </Route>
            <Route path="catalogo_cuenta" element={<CatalogoCuentas />} />
            <Route path="miembros">
              <Route index element={<MembersList />} />
              <Route path="fallecidos" element={<MembersDeceaseds />} />
              <Route path="documentos" element={<DocumentsList />} />
              <Route path="nuevo" element={<MemberForm />} />
              <Route path=":memberId" element={<MemberForm />} />
              <Route path="aspirante" element={<Aspiring/>}>
                <Route path=":aspiranteId" element={<MemberForm />} />
              </Route>              
            </Route>
            <Route path="/reportes" element={<Reports/>}/>
          </Route>
          <Route path="/mensajeria" element={<Messaging />} />
          {/*
        <Route
        path="/formulario_cobro"
        element={<ChargeForm/>}
  />*/}
        </Route>

        {/*} 
      
      
      
      
      
      
      <PrivateRouter
        key="form_charge"
        path="/formulario_cobro"
        component={ChargeForm}
      />
      <PrivateRouter
        key="form_charge"
        path="/formulario_cobro"
        component={ChargeForm}
      />
      
      
      
      
    
      
    
     
  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
