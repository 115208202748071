import React, { useMemo } from "react";
import { TextField, Input, InputLabel, FormControl } from "@mui/material";
import Select from "react-select";
import geographical from "../../../const/geographicals";
import { TextMaskPhone, TextMaskIdentity } from "../../MaskInput/MaskInput";
import clsx from "clsx";
import styles from "./personalInfo.module.scss";

let municipalityTransform = { value: "", label: "" };
let providenceTransform = { value: "", label: "" };
let regionTransform = { value: "", label: "" };

const borderStylesSelect = {
  control: (styles) => ({
    ...styles,
    border: "none",
    borderBottom: "1px solid",
    borderRadius: 0,
  }),
};

function PersonalInfo({
  form: {
    names,
    familyName,
    identifier,
    registrationDate,
    street,
    number,
    city,
    phone,
    cellphone,
    email,
    municipality,
  },
  handleForm,
  postValidation,
  handlerMunicipality,
}) {
  function selectDropDown(value) {
    providenceTransform = geographical.Providence.find(
      (pro) => pro.value === value.parent
    );
    regionTransform = geographical.Region.find(
      (reg) => reg.value === providenceTransform.parent
    );
    handlerMunicipality(value, providenceTransform, regionTransform);
  }

  useMemo(() => {
    if (!!municipality) {
      municipalityTransform =
        geographical.Municipio.find((mun) => mun.value === municipality) ||
        municipalityTransform;
      providenceTransform =
        geographical.Providence.find(
          (pro) => pro.value === municipalityTransform.parent
        ) || providenceTransform;
      regionTransform =
        geographical.Region.find(
          (reg) => reg.value === providenceTransform.parent
        ) || regionTransform;
    } else if (!municipality) {
      municipalityTransform = { value: "", label: "" };
      providenceTransform = { value: "", label: "" };
      regionTransform = { value: "", label: "" };
    }
  }, [municipality]);

  return (
    <>
      <div className={styles.inputContainer}>
        <TextField
          required
          name="names"
          value={names}
          label="Nombres"
          margin="normal"
          fullWidth
          onChange={handleForm}
          error={!postValidation.names}
          className={clsx(styles.inputMedium, styles.inputRightSpace)}
          variant="standard"
        />
        <TextField
          required
          id="lastname"
          label="Apellidos"
          margin="normal"
          fullWidth
          name="familyName"
          value={familyName}
          onChange={handleForm}
          error={!postValidation.familyName}
          className={styles.inputMedium}
          variant="standard"
        />
      </div>
      <div className={styles.inputContainer}>
        <FormControl
          fullWidth
          margin="normal"
          error={!postValidation.identifier}
          required
          className={clsx(styles.inputMedium, styles.inputRightSpace)}
        >
          <InputLabel htmlFor="identifier-input">Cédula</InputLabel>
          <Input
            value={identifier}
            onChange={handleForm}
            name="identifier"
            id="identifier-input"
            inputComponent={TextMaskIdentity}
            fullWidth
            error={!postValidation.identifier}
            required
            variant="standard"
          />
        </FormControl>
        <TextField
          id="date"
          label="Fecha de inscripción"
          type="date"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          fullWidth
          name="registrationDate"
          value={
            Date.parse(registrationDate)
              ? new Date(registrationDate).toISOString().slice(0, 10)
              : ""
          }
          onChange={handleForm}
          error={!postValidation.registrationDate}
          className={styles.inputMedium}
          variant="standard"
        />
      </div>
      <div className={styles.inputContainer}>
        <TextField
          required
          id="street"
          label="Calle"
          margin="normal"
          fullWidth
          name="street"
          value={street}
          onChange={handleForm}
          variant="standard"
        />

        {/*
          <TextField
            required
            id="city"
            label="Distrito"
            margin="normal"
            fullWidth
            name="city"
            value={city}
            onChange={handleForm}
            className={styles.inputLarge}
          />
          */}
      </div>
      <div>
        <div className={styles.inputContainer}>
          <TextField
            id="number"
            label="Numero"
            margin="normal"
            fullWidth
            name="number"
            value={number}
            onChange={handleForm}
            className={clsx(styles.inputSmall, styles.inputRightSpace)}
            variant="standard"
          />

          <div className={clsx(styles.inputMedium, styles.boxThis)}>
            <label>Municipio</label>
            <Select
              label="Municipio"
              //component={Select}
              name="municipality"
              options={geographical.Municipio}
              onChange={selectDropDown}
              placeholder="Municipio"
              value={municipalityTransform}
              className={clsx({
                [styles.error_border]: !postValidation.municipality,
              })}
              classNamePrefix="react-select"
              variant="standard"
              styles={borderStylesSelect}
            />
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={clsx(styles.inputMedium, styles.inputRightSpace)}>
            <label>Provincia</label>
            <Select
              name="providence"
              options={geographical.Providence}
              placeholder="Provincia"
              isDisabled={true}
              value={providenceTransform}
              className={`select_geographical ${
                !postValidation.providence ? "error_border" : ""
              }`}
              styles={borderStylesSelect}
            />
          </div>
          <div className={styles.inputMedium}>
            <label>Región</label>
            <Select
              name="region"
              options={geographical.Region}
              placeholder="Región"
              isDisabled={true}
              value={regionTransform}
              className={`select_geographical ${
                !postValidation.region ? "error_border" : ""
              }`}
              styles={borderStylesSelect}
            />
          </div>
        </div>
      </div>
      <div className={styles.inputContainer}>
        <FormControl
          fullWidth
          margin="normal"
          error={!postValidation.cellphone}
          required
          className={clsx(styles.inputSmall, styles.inputRightSpace)}
        >
          <InputLabel htmlFor="phone-input">Teléfono</InputLabel>
          <Input
            value={phone}
            onChange={handleForm}
            name="phone"
            id="phone-input"
            inputComponent={TextMaskPhone}
            fullWidth
            error={!postValidation.cellphone}
            required
          />
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
          error={!postValidation.cellphone}
          required
          className={clsx(styles.inputSmall, styles.inputRightSpace)}
        >
          <InputLabel htmlFor="cellphone-input">Celular</InputLabel>
          <Input
            value={cellphone}
            onChange={handleForm}
            name="cellphone"
            id="cellphone-input"
            inputComponent={TextMaskPhone}
            fullWidth
            error={!postValidation.cellphone}
            required
          />
        </FormControl>
        <TextField
          required
          id="email"
          label="Email"
          margin="normal"
          fullWidth
          name="email"
          value={email}
          onChange={handleForm}
          error={!postValidation.email}
          helperText={
            !postValidation.email && "Campo en blanco o email inválido"
          }
          className={styles.inputMedium}
          variant="standard"
        />
      </div>
    </>
  );
}

export default PersonalInfo;
