import React from "react";
import { TextField, Input, InputLabel, FormControl } from "@mui/material";
import clsx from "clsx";
import styles from "./aspiringForm.module.scss";

export const AspiringForm = ({
    form: {
      schoolOrigin,
      university,
      levelSDAentry
    }, 
    errors, 
    handleForm }) => {
  return (
    <div>
      <TextField
        required
        name="schoolOrigin"
        value={schoolOrigin}
        label="Escuela de procedencia"
        margin="normal"
        fullWidth
        onChange={handleForm}
        error={!errors.schoolOrigin}
        className={clsx(styles.inputMedium, styles.inputRightSpace)}
        variant="standard"
      />
      <div className={styles.inputContainer}>
      <TextField
        required
        name="university"
        value={university}
        label="Universidad avala el Hosp. docente"
        margin="normal"
        fullWidth
        onChange={handleForm}
        error={!errors.university}
        className={clsx(styles.inputMedium, styles.inputRightSpace)}
        variant="standard"
      />
      <TextField
        required
        name="levelSDAentry"
        value={levelSDAentry}
        label="Nivel que cursa al ingreo SDA"
        margin="normal"
        fullWidth
        onChange={handleForm}
        error={!errors.levelSDAentry}
        className={clsx(styles.inputSmall)}
        variant="standard"
      />
      </div>
    </div>
  );
};
