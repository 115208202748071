import React from "react";
import PropTypes from "prop-types";
import { Fab } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
} from "@mui/material";
import floor from "lodash.floor";
import { formatCurrency, calculateAmount } from "../../../utils/utils";
import styles from "./servicesDetails.module.scss";

function ServicesDetails({
  compost,
  services,
  removeServices,
  decreaseServiceQuantity,
  isDisabled,
  applyItebis,
  itebis = 18,
  currencyRate,
  currencyType,
}) {
  const transformAmount = calculateAmount(
    services[0]?.body?.currency,
    currencyType,
    currencyRate
  );

  const grossTotal = services.length
    ? services.reduce(
        (services, service) => (services += service.quantity * service.price),
        0
      )
    : 0;

  let TotalWithDiscount = services.length
    ? services.reduce((totalWithDisc, service) => {
        let total = +service.quantity * +service.price;
        totalWithDisc =
          totalWithDisc + (total - total * (+(service.discount || 0) / 100));
        return totalWithDisc;
      }, 0)
    : 0;

  let totalWithItebis = 0;
  let totalForItebis = 0;

  if (applyItebis) {
    totalWithItebis = services.length
      ? services.reduce((totalWithDisc, service) => {
          let total = +service.quantity * +service.price;

          let totalDisc = total - total * (+(service.discount || 0) / 100);

          if (!service.body.excludeTax) {
            totalForItebis = totalForItebis + totalDisc;

            totalWithDisc = floor(
              totalWithDisc + (totalDisc + (totalDisc * itebis) / 100),
              2
            );
          } else {
            totalWithDisc = floor(totalWithDisc + totalDisc, 2);
          }

          return totalWithDisc;
        }, 0)
      : 0;

    totalWithItebis = floor(transformAmount(totalWithItebis), 2);
  }

  TotalWithDiscount = floor(transformAmount(TotalWithDiscount), 2);

  const totalItebis = applyItebis
    ? floor((totalForItebis * itebis) / 100, 2)
    : 0;

  const granTotal = floor(applyItebis ? totalWithItebis : TotalWithDiscount, 2);

  return (
    <Paper className={styles.table}>
      <Table
        //className={classes.table}
        aria-label="simple table"
        stickyHeader
        height={"40vh"}
      >
        <TableHead>
          <TableRow>
            <TableCell size="small">Controles</TableCell>
            <TableCell>Servicios</TableCell>
            <TableCell align="right">Cant</TableCell>
            <TableCell align="right">Precio</TableCell>
            <TableCell align="right">Precio Bruto</TableCell>
            <TableCell align="right">Desc</TableCell>
            {applyItebis ? <TableCell align="right">ITBIS</TableCell> : null}
            <TableCell align="right">Precio Neto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((row, index) => {
            return (
              <TableRow key={row.label + " " + index}>
                <TableCell size="small">
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="reducir"
                    //className={`${classes.customButton} ${classes.minorOneButton}`}
                    onClick={() => decreaseServiceQuantity(row.value)}
                    disabled={
                      isDisabled ||
                      (compost && row.body.isSystem && row.body.isMembership)
                    }
                  >
                    <RemoveCircle />
                  </Fab>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="borrar"
                    //className={classes.customButton}
                    onClick={() => removeServices(row.value)}
                    disabled={
                      isDisabled ||
                      (compost && row.body.isSystem && row.body.isMembership)
                    }
                  >
                    <DeleteForeverIcon />
                  </Fab>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">
                  {formatCurrency(transformAmount(row.price))}
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(transformAmount(row.quantity * row.price))}
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(row.discount || 0)}%
                </TableCell>
                {applyItebis ? (
                  <TableCell align="right">
                    {formatCurrency(
                      row.body.excludeTax
                        ? 0
                        : (transformAmount(row.quantity * row.price) * itebis) /
                            100 || 0
                    )}
                  </TableCell>
                ) : null}
                <TableCell align="right">
                  {formatCurrency(
                    floor(
                      transformAmount(row.quantity * row.price) -
                        (transformAmount(row.quantity * row.price) *
                          transformAmount(row.discount || 0)) /
                          100,
                      2
                    )
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={applyItebis ? 4 : 5} />
            <TableCell>
              Total B.{" "}
              {`${currencyType} ${formatCurrency(transformAmount(grossTotal))}`}
            </TableCell>
            {applyItebis ? (
              <>
               <TableCell align="right">
              
             </TableCell>
              <TableCell align="right">
                Total ITBIS {formatCurrency(totalItebis)}
              </TableCell>
              </>
            ) : null}
            <TableCell align="right">
              Total Net {`${currencyType} ${formatCurrency(granTotal)}`}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

ServicesDetails.propTypes = {
  services: PropTypes.array.isRequired,
};
export default ServicesDetails;
