import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getService,
  saveService,
  updateService,
} from "../../services/servicesAPI";
import { findAllcatalogoCuenta } from "../../services/catalogoCuentaAPI";
import { ServiceForm } from "../../components/serviceForm/ServiceForm";


const formData = {
  id: "",
  description: "",
  price: "",
  accountNumber: "",
  accountName: "",
  currency: "DOP",
  isMembership: false,
  forAspiring: false,
  forPerson: false,
  forMember: true,
  forCompany: false,
  excludeTax: false
};

const validFormInitializer = {
  description: true,
  price: true,
  accountNumber: true
};


function Form() {
  const [form, setForm] = useState(formData);
  const [validForm, setValidForm] = useState(validFormInitializer);
  const [msgHttp, setMsgHttp] = useState({
    msg: "",
    variant: "",
  });
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { servicioId } = useParams();

  function onChangeFormInputs({ target: { name, value } }) {
    setForm({
      ...form,
      [name]: value,
    });
  }

  function onChangeFormCheckbox({target: { name, checked }}) {
    setForm({
      ...form,
      [name]: checked,
    });
  }

  function validateForm(form) {
    const inputs = ["description", "price", "accountNumber"];
    let isValid = true;
    let validatedState = inputs.reduce((acc, crr) => {
      acc[crr] = true;
      if (!form[crr]) {
        acc[crr] = false;
        isValid = false;
      }
      return acc;
    }, {});
    return { isValid, validatedState };
  }

  async function onSaveService() {
    let formResultValidation = validateForm(form);
    try {
      if (formResultValidation.isValid) {
        setLoading(true);
        const { accountName, ...rest } = form;
        let { body: payload } = await saveService({
          ...rest
        });

        if (payload.success) {
          setMsgHttp({
            msg: "El servicio se guardo con exito!",
            variant: "success",
          });
          window.setTimeout(() => {
            navigate("/servicios");
          }, 990);
        }
      } else {
        setMsgHttp({
          msg: "Hubo un error al intentar guardar",
          variant: "warning",
        });
        setValidForm(formResultValidation.validatedState);
        setLoading(false)
      }
    } catch (e) {
      setMsgHttp({
        msg: "Hubo un error al intentar guardar",
        variant: "error",
      });
      setLoading(false)
    }

  }

  async function onUpdateService() {
    let formResultValidation = validateForm(form);
    try {
      if (formResultValidation.isValid) {
        const { accountName, ...rest } = form;
        let { body: payload } = await updateService({
            ...rest
          });
        if (payload?.success) {
          setMsgHttp({
            msg: "El servicio se actualizo con exito!",
            variant: "success",
          });
          window.setTimeout(() => {
            navigate("/servicios");
          }, 990);
        } else {
          setMsgHttp({
            msg: "Hubo un error al intentar actulizar",
            variant: "warning",
          });
          setValidForm(formResultValidation.validatedState);
        }
      } else {
        setMsgHttp({
          msg: "Hubo un error al intentar actualizar",
          variant: "warning",
        });
        setValidForm(formResultValidation.validatedState);
        setLoading(false)
      }
    } catch (e) {
      setMsgHttp({
        msg: "Hubo un error al intentar actualizar",
        variant: "error",
      });
      setLoading(false)
    }
  }

  const selectAcccount = (acc) => {
    setForm((prev) => ({
      ...prev,
      accountNumber: acc.value,
      accountName: acc.label
    }));
  };

  useEffect(() => {
    if (servicioId) {
      (async () => {
        try {
          let { body: payload } = await getService(servicioId);
          setForm({
            ...payload.data,
          });
        } catch (error) {
          setMsgHttp({
            msg: "Hubo un inconveniente buscando el servicio",
            variant: "error",
          });
        }
      })();
    }
  }, [servicioId]);

  useEffect(() => {
    (async () => {
      try {
        const payload = await findAllcatalogoCuenta();
        if (payload.body.success) {
          const accs = payload.body.data.map((acc) => ({
            value: acc.code,
            label: acc.descripcion,
          }));
          setLoading(false);
          setAccounts(accs);
        }
      } catch (e) { }
    })();
  }, []);

  return (
    <ServiceForm
      form={form}
      validForm={validForm}
      msgHttp={msgHttp}
      onChangeFormInputs={onChangeFormInputs}
      onSaveService={onSaveService}
      onUpdateService={onUpdateService}
      selectAcccount={selectAcccount}
      accounts={accounts}
      disabled={loading}
      onChangeFormCheckbox={onChangeFormCheckbox}
    />
  );
}

export default Form;
