import React, { useEffect, useState } from "react";
import { ButtonLink } from "../../components";
import API from "../../config/api";
import { TableInvoiceCredit } from "../../components/InvoiceCredit/Table";
import InvoicePrintPage from "../ChargeManagement/components/InvoicePrintPage/InvoicePrintPage";
import styles from "./list.module.scss";

const InvoicePenddingForPayment = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceToPrint, setInvoiceToPrint] = useState({});
  const [showPrint, setShowPrint] = useState(false);

  const loadPrintAndShow = async (item) => {
    try {
      const invoice = await API.get(`invoice/${item.id}`);
      
      const toPrint = {
        ...invoice,
        client: {
          name:  invoice?.member?.fullName || invoice?.company?.name || invoice.owner.name,
          identifier: invoice?.member?.identifier || invoice?.company?.registerNumber || invoice.owner.reference
        },
        isMember: !!invoice.member,
      };
  
      if (invoice.taxpayerRegistryNumber && invoice.tax_number?.lenth) {
        toPrint.taxReceiptNumber = invoice.tax_number[0].taxNumberComplete;
      }
      
      setInvoiceToPrint(toPrint);
      setShowPrint(true);
      setTimeout(() => {
        window.print();
      }, 1000);
    } catch(error) {}
  }

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const payload = await API.get("invoice/invoice_quotas_pendding");

        let invoices = [];
        payload.forEach((item) => {
          invoices.push(item);
        });
        setList(invoices);
      } catch (error) {
        console.log("ERROR***", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <>
    <div className={styles.container}>
      <ButtonLink to="/recibos/credito/pagados">Factura parciales</ButtonLink>
      <TableInvoiceCredit invoiceCredit={list} loading={isLoading} loadPrintAndShow={loadPrintAndShow} />
    </div>
    {showPrint && <InvoicePrintPage {...invoiceToPrint} isHalfPaper />}
    </>
  );
};

export default InvoicePenddingForPayment;
