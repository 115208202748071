import validator from "validator";

/**
 * @param str
 * @returns {boolean|*}
 */
export const isEmail = (str) => validator.isEmail(str);

/**
 * @param str
 * @returns {boolean}
 */
export const isEmpty = (str) =>
  typeof str === "string" &&
  validator.isEmpty(str, { ignore_whitespace: false });

/**
 * @param str
 * @returns {boolean}
 */
export const isInt = (str) => validator.isInt(str);

export const onlyNumber = (str) => /^[0-9]+$/g.test(str);

//export const isPersonIdentityNumber = (str) => 
