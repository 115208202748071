import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import styles from "./paymentType.module.scss";

function StyledRadio(props) {
  //const classes = useStyles();
  return (
    <Radio
      //className={classes.root}
      disableRipple
      color="default"
      //checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      //icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const PaymentType = ({ onChangeInput, paymentType, isDisabled }) => {
  return (
    <FormControl>
      <label className={styles.labelpaymentType}>Tipo de pago</label>
      <RadioGroup 
        aria-label="tipo de pago" 
        value={paymentType} 
        className={styles.containerPaymentType}
      >
        <div>
        <FormControlLabel
          value="cash"
          name="paymentType"
          control={<StyledRadio />}
          label="Efectivo"
          onChange={onChangeInput}
          disabled={isDisabled}
        />
        <FormControlLabel
          value="creditCard"
          name="paymentType"
          control={<StyledRadio />}
          label="Tarjeta de credito"
          onChange={onChangeInput}
          disabled={isDisabled}
        />
        </div>
        <div>
        <FormControlLabel
          value="bankTransfer"
          name="paymentType"
          control={<StyledRadio />}
          label="Transferencia bancaria"
          onChange={onChangeInput}
          disabled={isDisabled}
        />
        <FormControlLabel
          value="bankDeposit"
          name="paymentType"
          control={<StyledRadio />}
          label="Deposito bancario"
          onChange={onChangeInput}
          disabled={isDisabled}
        />
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default PaymentType;
