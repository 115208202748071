import React, {useState} from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Collapse,
  Typography,
  Fab
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
/*
const useStyles = makeStyles(theme => ({
  fab: {
      margin: theme.spacing(1),
    },
}))
*/
function Row({
  reference,
  removeReferenceMember,
  isCompanion
}) {
    const [open, setOpen] = useState(false);
    //const classes = useStyles();
    return (
        <>
          <TableRow>
                <TableCell>
                  <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                 </TableCell>
                 <TableCell>{reference.service.label}</TableCell>
                 <TableCell>{`${reference.members.length}/${reference.service.quantity}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Typography variant="h6" gutterBottom component="div">
                  {isCompanion ? "Individuo" : "Miembros"}
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell size="small">Controles</TableCell>
                        <TableCell>{isCompanion ? "Individuo" : "Miembro"}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                         reference.members.map((member, idx) => (
                             <TableRow key={member.label + " " + idx}>
                               <TableCell size="small">
                                 <Fab
                                   size="small"
                                   color="secondary"
                                   aria-label="borrar"
                                   //className={`${classes.customButton} ${classes.minorOneButton}`}
                                   onClick={() => {removeReferenceMember(reference.service, member)}}                          
                                 >
                                   <DeleteForeverIcon />
                                 </Fab>
                               </TableCell>
                               <TableCell component="th" scope="row">
                                 {member.label}
                                </TableCell>
                             </TableRow>
                         ))
                      }
                    </TableBody>
                  </Table> 
                </Box>
              </Collapse>
            </TableCell>  
          </TableRow>
        </>
    )
}

function MemberList({
  references,
  removeReferenceMember,
  isCompanion
}) {
    return (
        <TableContainer component={Paper}>
          <Table
            //className={classes.table}
            aria-label="simple table"
            stickyHeader
            height={"40vh"}
          >
            <TableHead>
              <TableRow>
                <TableCell/>                
                <TableCell>Servicios</TableCell>
                <TableCell>Total {isCompanion ? 'Individuo' : 'Miembro'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                references.map((reference, idx) => {
                return (
                  <Row 
                    key={`ref--${idx}`} 
                    reference={reference}
                    removeReferenceMember={removeReferenceMember}
                    isCompanion={isCompanion}
                  />)
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
    )
}


export default MemberList