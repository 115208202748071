import React, {useState} from "react";
import {
  Button,
  Dialog,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import clsx from "classnames";
import "./AlertDialog/AlertDialog.css";

export default function AlertDialog({
  isOpen,
  message,
  onOk,
  onCancel,
  title,
  type,
  isLoading,
  withReason,
}) {
  const [open, setOpen] = useState(isOpen);
  const [reason, setReason] = useState("");
  const [requireFild, setRequireFild] = useState({reason: ""})

  function handleClose() {
    onCancel();
    setOpen(false);
  }

  function handleOk() {
    if (withReason && !reason) {
      setRequireFild({reason: "El campo es requerido."})
    } else {
      onOk(withReason ? reason : null);
      setOpen(false);
    }
  }

  function onHandelText(e) {
    const { value } = e.target;
    setReason(value);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={clsx({ delete_banner: type === "delete" })}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
          {withReason ? (
            <DialogContentText id="alert-dialog-why">
              <div className={"reason_container"}>
              <label>Rason:</label>
              <textarea
                id="reason"
                name="reason"
                rows="5"
                cols="33"
                autocomplete="off"
                maxlength="300"
                value={reason}
                onChange={onHandelText}
                className={clsx({"reason_error_textarea": requireFild.reason}, "reason_textarea")}
              />
              {requireFild.reason ? <span className={"reason_error"}>{requireFild.reason}</span> : null}
              </div>
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleOk}
            color="primary"
            autoFocus
          >
            {isLoading ? <CircularProgress /> : "Si"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
