import React, { useState } from 'react';
import clsx from 'classnames';
import { TextField } from "@mui/material";
import styles from './companion.module.scss'

const Companion = ({onInputChange, member}) => {

    const onChangeInput = (e) => {
      onInputChange(prev => ({...prev, label: e.target.value}));
      
    }

    const onChangeInputReference = (e) => {
      onInputChange(prev => ({...prev, value: e.target.value}));
  }

    return (
    <div className={styles.container}>
        <label>Acompañante</label>
        <TextField
          id="companion"
          className={styles.textField}
          label="Acompañante"
          margin="normal"
          variant="filled"
          onChange={onChangeInput}
          value={member.label}
          //error={!!validState.quantity}
          //helperText={!!validState.quantity && validState.quantity}
        />
         <TextField
          id="referencie"
          className={styles.textField}
          label="Referencia"
          margin="normal"
          variant="filled"
          onChange={onChangeInputReference}
          value={member.value}
          //error={!!validState.quantity}
          //helperText={!!validState.quantity && validState.quantity}
        />
    </div>)
}

export default Companion;

