import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  TextField,
  Breadcrumbs,
  Paper,
  Typography
} from "@mui/material";
import { SnackbarContentWrapper } from '../../components';
import { 
  saveCompany, 
  getCompany,
  updateCompany
} from "../../services/companyAPI";
import { useNavigate, Link, useParams } from "react-router-dom";
import styles from "./company.module.scss";

const formInitalState = {
    id: "",
    name: "",
    searchbleName: "",
    registerNumber: "",
    address: "",
    isActive: true
}

const validFormInital = {
  name: true,
  registerNumber: true,
  address: true
}

function Form() {
    const [form, setForm] = useState(formInitalState);
    const [validForm, setValidForm] = useState(validFormInital);
    const [msgHttp, setMsgHttp] = useState({
        msg: '',
        variant: ''
    })
    const navigate = useNavigate();
    const {companyId} = useParams();

    function onChangeFormInputs({ target: { name, value } }) {
      setForm({
        ...form,
        [name]: value,
      });
    }

    function validateForm(form) {
        const inputs = ["name", "registerNumber", "address"]
        let isValid = true;
        let validatedState = inputs.reduce((acc, crr) => {
            acc[crr] = true;
            if (!form[crr]) {
                acc[crr] = false;
                isValid = false;
            }
            return acc;
        },{});
        return { isValid, validatedState };
    }

    async function onSaveCompany() {
        let formResultValidation = validateForm(form);
        let payload = {};
        if (formResultValidation.isValid) {
          if (form._id) {
            let { body } = await updateCompany(form);
            payload = body;        
          } else {
            let { body } = await saveCompany(form);
            payload = body;     
          }
          if (payload.success) {
            setMsgHttp({
                msg: `La compañía se ${form._id ? "actualizado" : "guardo" } con exito!`,
                variant: "success"
            });
          }
          window.setTimeout(() => {
            navigate("/companias");
          }, 990);
        } else {
            setMsgHttp({
                msg: "Hubo un error al intentar guardar.",
                variant: "warning"
            });
            setValidForm(formResultValidation.validatedState);
        }
    }

    useEffect(() => {
      (async() => {
        if (companyId) {
          const payload = await getCompany(companyId);
          setForm(payload.body.data);
         }
      })()      
    },[companyId])

    return (
        <Container maxWidth="sm">
            <Paper>
                <Breadcrumbs>
                  <Link color="inherit" to="/">
                      Inicio
                  </Link>
                  <Typography color="textPrimary">Formulario de compañía</Typography>
                </Breadcrumbs>
            </Paper>
            {
                !!msgHttp.msg && (
                    <SnackbarContentWrapper
                      variant={msgHttp.variant}
                      message={msgHttp.msg}
                    />
                )
            }
            <div className={styles.companyFormContainer}>
               <TextField
                 id="name"
                 label="Nombre"
                 value={form.name}
                 name="name"
                 onChange={onChangeFormInputs}
                 className={styles.textField}
                 error={!validForm.name}
               />
               <TextField
                 id="registerNumber"
                 label="No. Registro"
                 value={form.registerNumber}
                 name="registerNumber"
                 onChange={onChangeFormInputs}
                 className={styles.textField}
                 error={!validForm.registerNumber}
               />
               <TextField
                 id="address"
                 label="Dirección"
                 value={form.address}
                 name="address"
                 onChange={onChangeFormInputs}
                 className={styles.textField}
                 error={!validForm.address}
               />
               <Button 
                 variant="contained" 
                 color="primary" 
                 onClick={onSaveCompany}
                 //className={classes.buttonSubmit}
               >
                 { form.id || companyId ? "Actualizar" : "Guardar" }
               </Button>
            </div>
        </Container>
    )
}

export default Form;