import * as React from "react";
import {
  getMember
} from "../../../services/memberAPI";
import {
  Container
} from "@mui/material";
import TableMember from "../../../components/memberList/tableMember/TableMember";
import { SnackbarContentWrapper } from "../../../components";
import style from '../../../styles/general.module.scss';

const ITEM_PEER_PAGE = 10;
function List() {
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [httpStatus, setHttpStatus] = React.useState(null);
    const [members, setMembers] = React.useState({total: 0, members: []});

   function handleChangePage(e, page) {
    setPage(page);
   }

   async function fillTableWithDeceaseds(page) {
        setLoading(true);
        try {
          let { body: membersFetched } = await getMember(
            "all",
            page,
            ITEM_PEER_PAGE,
            null,
            null,
            true    
          );

          if (membersFetched.success) {
            setMembers({total: membersFetched.total, members: membersFetched.data});
          } else {
            setMembers({total: 0, members: []});
          }
        } catch (error) {
          setHttpStatus({ type: 'error', msg: "Error al cargar los miembros"});
        }
        setLoading(false);
    }

    React.useEffect(() => {
        fillTableWithDeceaseds(page);
      }, [page]);

    return (
        <Container className={style.container}>
          {
        httpStatus != null ? (
              <SnackbarContentWrapper
                open={httpStatus != null}
                variant={httpStatus.type}
                message={httpStatus.msg}
                onSetHttp={setHttpStatus}
                onClose={() => setHttpStatus(null)}
              />
            ) 
        : null
      }
          <h1>Lista de Fallecidos</h1>
          <TableMember
            loading={loading}
            allowDelete={false}
            membersArray={members.members}
            page={page}
            totalMember={members.total}
            handleChangePage={handleChangePage}
          />
        </Container>
    )
}

export default List;