import API from "../config/api";

const BASE = "invoice";

export function saveInvoice(invoice) {
  return API.post(`${BASE}/save`, invoice);
}

export function getInvoices(status, fullName, page, pageSize = 10) {
  let query = `?status=${status}`;

  if (fullName) {
    query += `&fullName=${fullName}`;
  }
  if (page >= 0) {
    query += `&page=${page}`;
  }
  if (pageSize) {
    query += `&pageSize=${pageSize}`;
  }
  return API.get(`${BASE}/all${query}`);
}

export function changeInvoiceStatus(id, status, reason) {
  return API.put(`${BASE}/change-status/${id}/${status}`,{reason});
}

export function getInvoiceDetail(dateIn, dateFn) {
  return API.get(`${BASE}/caja-detalles?dateIn=${dateIn}&dateFn=${dateFn}`);
}
