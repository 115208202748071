import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "./hospitalWorksDisplay.module.scss";

/*
  city: '',
  endorsementOfTheUniversity: '',
  country: '',
  sector: '',
  fax: '',
  type: '',
  */
function HospitalWorksDisplay({ otherPostgraduate, removeOtherPostgraduate }) {
  return (
    <Table className={styles.tableVerticalSpace}>
      <TableHead>
        <TableRow>
          <TableCell align="left">Nombre</TableCell>
          <TableCell align="right">Tipo</TableCell>
          <TableCell align="right">Hospital</TableCell>
          <TableCell align="right">Ciudad</TableCell>
          <TableCell align="right">Telefono</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {otherPostgraduate.map((row, index) => (
          <TableRow key={`${row.name}-${index}`}>
            <TableCell component="th" scope="row" align="left">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.type}</TableCell>
            <TableCell align="right">{row.hospital}</TableCell>
            <TableCell align="right">{row.city}</TableCell>
            <TableCell align="right">{row.phone}</TableCell>
            <TableCell
              onClick={() => {
                removeOtherPostgraduate(index);
              }}
              className="remove_style"
            >
              <span role="img" aria-label="Borrar el posgrado">
                ✖️
              </span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default HospitalWorksDisplay;
