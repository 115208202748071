import React, { useContext } from 'react';

const ToastContext = React.createContext();
const ToastSetterContext = React.createContext();

function ToastContextProvider({ children }) {
    const [toast, setToast] = React.useState({
        open: false,
        message: '',
        variant: 'success'
    });
    
    return (
        <ToastContext.Provider value={toast}>
          <ToastSetterContext.Provider value={setToast}>
            {children}
          </ToastSetterContext.Provider>
        </ToastContext.Provider>
    );
}

function useToast() {
    const context = useContext(ToastContext);
    if (context === undefined) {
        throw new Error("useToast must be used within a ToastContext");
    }

    return context;
}

function useToastSetter() {
    const context = useContext(ToastSetterContext);

    if (context === undefined) {
        throw new Error("useToastSetter must be used within a ToastContext");
    }

    return context;
}

export {
    useToastSetter,
    useToast,
    ToastContextProvider
}