import React from "react";
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Icon,
  TableContainer,
  TablePagination
} from "@mui/material";
import { formatCurrency } from "../../../utils/utils";

TableInvoice.propTypes = {
  invoices: PropTypes.shape({
    total: PropTypes.number,
    invoices: PropTypes.array
  }),
  stageForChangeStatus: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadPrintAndShow: PropTypes.func,
  positionStart: PropTypes.number,
  handleChangePage: PropTypes.func
};

function TableInvoice({
  invoices,
  stageForChangeStatus,
  loading,
  handleChangePage,
  positionStart,
  loadPrintAndShow,
  page
}) {
  return (
    <>
      {loading ? (
        <>
          <CircularProgress />
          <p>Cargando...</p>
        </>
      ) : (
        <React.Fragment>
        <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">No.</TableCell>
              <TableCell align="left">Titular</TableCell>
              <TableCell align="left">Moneda</TableCell>
              <TableCell align="left">Total</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Factura No</TableCell>
              <TableCell align="left">RNC</TableCell>
              <TableCell align="left">Opción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.invoices.map((row, index) => (
              <TableRow key={`member-${index}`}>
                <TableCell align="left">{++positionStart || 0}</TableCell>
                <TableCell>{row?.member?.fullName || row?.company?.name || row.owner.name}</TableCell>
                <TableCell align="left">{row.currencyType}</TableCell>
                <TableCell align="left">{formatCurrency(row.total)}</TableCell>
                <TableCell align="left">{row.status === "paid" ? "Pago" : row.status}</TableCell>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.taxpayerRegistryNumber || "N/A"}</TableCell>
                
                <TableCell align="left">
                  {row.status === "paid..." ? (
                    <Icon className="table_invoice" onClick={() => stageForChangeStatus(row)}>
                      delete_forever
                    </Icon>
                  ) : (
                    <Icon className="table_invoice" onClick={() => stageForChangeStatus(row)}>
                      rotate_left
                    </Icon>
                  )}

                  <Icon
                    className="table_invoice"
                    onClick={() => {
                      loadPrintAndShow(row);
                    }}
                  >
                    local_printshop
                  </Icon>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>          
        </Table>
        </TableContainer>         
         <TablePagination
         rowsPerPageOptions={[10]}
         component="div"
         count={invoices.total}
         rowsPerPage={10}
         page={page}
         onPageChange={handleChangePage}
       />         
       </React.Fragment>
      )}
    </>
  );
}

export default TableInvoice;
