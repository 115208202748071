import React from "react";
import {
  Container,
  Button,
  TextField,
  Paper,
  Breadcrumbs,
  Typography,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { SnackbarContentWrapper } from "../";
import SelectUI from "react-select";
import clsx from "clsx";
import NumberFormatInput from "../NumberFormatInput/NumberFormatInput";
import styles from "./serviceForm.module.scss";

const borderStylesSelect = {
  control: (styles) => ({
    ...styles,
    border: "none",
    borderBottom: "1px solid",
    borderRadius: 0,
  }),
};

export const ServiceForm = ({
  onChangeFormInputs,
  msgHttp,
  form,
  validForm,
  onSaveService,
  onUpdateService,
  selectAcccount,
  accounts,
  disabled,
  onChangeFormCheckbox,
}) => (
  <Container maxWidth="sm">
    <Paper>
      <Breadcrumbs aria-label="Breadcrumb">
        <Link color="inherit">Inicio</Link>
        <Typography color="textPrimary">Formulario de servicio</Typography>
      </Breadcrumbs>
    </Paper>

    {!!msgHttp.msg && (
      <SnackbarContentWrapper
        variant={msgHttp.variant}
        message={msgHttp.msg}
        show={!!msgHttp.msg}
      />
    )}
    <form noValidate autoComplete="off" className={styles.servicesForm}>
      <div className={styles.serviceFor}>
        <h5 className={styles.serviceForTitle}>Utilizado por</h5>
        <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              name="forMember"
              onChange={onChangeFormCheckbox}
              checked={form.forMember}
            />
          }
          label="Miembro"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="forAspiring"
              onChange={onChangeFormCheckbox}
              checked={form.forAspiring}
            />
          }
          label="Aspirante"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="forPerson"
              onChange={onChangeFormCheckbox}
              checked={form.forPerson}
            />
          }
          label="Individuo"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="forCompany"
              onChange={onChangeFormCheckbox}
              checked={form.forCompany}
            />
          }
          label="Empresa"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="excludeTax"
              onChange={onChangeFormCheckbox}
              checked={form.excludeTax}
            />
          }
          label="Excluir ITBIS"
        />
      </FormGroup>
      </div>
      <TextField
        id="description"
        label="Desripción"
        value={form.description}
        name="description"
        onChange={onChangeFormInputs}
        error={!validForm.description}
        variant="standard"
      />
      <div className={styles.priceContainer}>
        <TextField
          name="price"
          label="Precio"
          value={form.price}
          onChange={onChangeFormInputs}
          placeholder="0"
          margin="normal"
          error={!validForm.price}
          InputProps={{ inputComponent: NumberFormatInput }}
          variant="standard"
          className={styles.input90}
        />
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 120 }}
          className={styles.currencySelect}
        >
          <InputLabel id="moneda-label">Moneda</InputLabel>
          <Select
            label="Moneda"
            value={form.currency}
            labelId="moneda-label"
            autoWidth
            onChange={onChangeFormInputs}
            name="currency"
          >
            <MenuItem value="DOP">DOP</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={clsx(styles.accountContainer, styles.inputBottomSpace)}>
        <div
          className={clsx(
            styles.box,
            styles.inputMedium,
            styles.inputRightSpace
          )}
        >
          <label>Cuenta</label>
          <SelectUI
            label="Cuenta"
            name="accountNumber"
            options={accounts}
            onChange={selectAcccount}
            placeholder="Cuenta"
            value={{
              value: form.accountNumber,
              label: form.accountName,
            }}
            className={clsx(styles.inputMedium, {
              [styles.error_border]: !validForm.accountNumber,
            })}
            classNamePrefix="react-select"
            variant="standard"
            styles={borderStylesSelect}
          />
        </div>
        <div className={clsx(styles.box)}>
          <label>Numero</label>
          <TextField
            id="accNumber"
            value={form.accountNumber}
            name="accNumber"
            readOnly
            variant="standard"
            error={!validForm.accountNumber}
            inputProps={{
              readOnly: true,
            }}
          />
        </div>
      </div>
      <div className={styles.btnContainer}>
        {form.id ? (
          <Button
            variant="contained"
            color="primary"
            onClick={onUpdateService}
            disabled={disabled}
          >
            Actualizar
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={onSaveService}
            disabled={disabled}
          >
            Guardar
          </Button>
        )}
      </div>
    </form>
  </Container>
);
