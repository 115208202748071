import React from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';
import PaymentType from "../PaymentType/PaymentType";
import styles from './titleBanner.module.scss';

function TitleBanner({
  voucherNumber,
  taxpayerRegistryNumber,
  onChangeInput,
  onApplyITEBIS,
  applyItebis,
  isDisabled,
  paymentType,
  ownerType
}) {

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.ncfContainer}>
          <TextField
            name="taxpayerRegistryNumber"
            label="RNC"
            margin="normal"
            variant="outlined"
            onChange={onChangeInput}
            value={taxpayerRegistryNumber}
            size="small"
            inputProps={{readOnly: ownerType === 'company'}}
          />
          <FormControlLabel
            control={<Checkbox onChange={onApplyITEBIS}
              name="applyItebis"
              checked={applyItebis} />} label="Aplicar ITEBIS"
            className={styles.itebisLabel}
          />
        </div>
      </div>
      <PaymentType
        onChangeInput={onChangeInput}
        paymentType={paymentType}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default TitleBanner;
