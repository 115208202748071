import React from "react";
import { bool, array } from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Icon,
} from "@mui/material";

function  TableDeposit({loading, deposits}) {
    return (
        <>
          {loading ? (
            <>
              <CircularProgress />
              <p>Cargando...</p>
            </>             
          ) :(<Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">No.</TableCell>
                    <TableCell align="left">Supplidor</TableCell>
                    <TableCell align="left">Tipo</TableCell>
                    <TableCell align="left">Monto</TableCell>
                    <TableCell align="left">Fecha</TableCell>
                    <TableCell align="left">Opción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {
                        deposits ? deposits.map((row, idx) => (
                            <TableRow key={`company-${idx}`}>
                              <TableCell align="left">{idx + 1}</TableCell>
                              <TableCell>{row.supplier.fullName}</TableCell>
                              <TableCell align="left">{row.type}</TableCell>
                              <TableCell align="left">{row.amount}</TableCell>
                              <TableCell align="left">{row.amount}</TableCell>
                              
                              <TableCell align="left">
                                <span>Print</span>
                              </TableCell>
                            </TableRow>
                        )) : <p>No existe ningun registro.</p>
                    }
                </TableBody>
              </Table>)}
        </>
    )
}

TableDeposit.propTypes = {
    loading: bool,
    depositos: array
}

export default TableDeposit;

