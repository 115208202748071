import React, { useState } from 'react';
import { TextField, Button } from "@mui/material";
import styles from "./membersUpdated.module.scss";

const MembersUpdated = ({ onClose, onGenerate }) => {
    const [dates, setDates] = React.useState({ dateIn: "", dateFn: "" });

    function handleChangeDate({ target: { name, value } }) {
        setDates((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    function handelOnGenerate() {
        if (dates.dateIn && dates.dateFn) {
            console.log("IN yes***", dates);
           onGenerate(dates.dateIn, dates.dateFn);
        }
    }

    return (
        <div className={styles.container}>
            <p className={styles.invoice_description}>
                Favor de insertar las fechas, para el reporte de miembros actualizados.
            </p>
            <div className={styles.dateContainer}>
                <TextField
                    id="dateIn"
                    label="Fecha inicio"
                    type="date"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    name="dateIn"
                    value={dates.dateIn}
                    onChange={handleChangeDate}
                // error={!!error}
                />
                <TextField
                    id="dateFn"
                    label="Fecha fin"
                    type="date"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    name="dateFn"
                    value={dates.dateFn}
                    onChange={handleChangeDate}
                // error={!!error}
                />
            </div>
            <div>
                <Button variant="contained" color="primary" onClick={handelOnGenerate}>
                    Generar
                </Button>

                <Button onClick={onClose} color="secondary">Cancelar</Button>
            </div>
        </div>
    )
}

export default MembersUpdated;
