import React, { useState, useEffect } from "react";
import { Container, Breadcrumbs, Link, Typography, Paper } from "@mui/material";
import {
  useParams,
  useNavigate
} from "react-router-dom";
import {
  postMember,
  getMemberByIdentifier,
  putMember,
} from "../../../services/memberAPI";
import { SnackbarContentWrapper } from "../../../components";
import PersonalInfo from "../../../components/memberForm/personalInfo/PersonalInfo";
import PostgraduateAnesthesia from "../../../components/memberForm/postgraduate/PostgraduateAnesthesia";
import OtherPostgraduate from "../../../components/memberForm/postgraduate/OtherPostgraduate";
import HospitalWorksDisplay from "../../../components/memberForm/hospitalWork/HospitalWorksDisplay";
import RowSettings from "../../../components/memberForm/rowSettings/RowSettings";
import DocumentAdded from "../../../components/memberForm/documentAdded/DocumentAdded";
import ButtonSpace from "../../../components/memberForm/buttonSpace/ButtonSpace";
import { isEmpty, isEmail, onlyNumber } from "../../../utils/validators";
import { scrollTop, transformText } from "../../../utils/utils";
import "./form.css";

const initialState = {
  names: "",
  searchableName: "",
  familyName: "",
  searchableFamilyName: "",
  identifier: "",
  registrationDate: "",
  street: "",
  number: "",
  city: "",
  phone: "",
  cellphone: "",
  email: "",
  municipality: "",
  providence: "",
  region: "",
  isUnCompleted: false,
  isDeceased: false,
  codeSchool: "",
  membershipToPay: 0,
  sequence: 0,
  presidentRole: false,
  postgraduateAnesthesia: {
    hospital: "",
    city: "",
    country: "",
    street: "",
    yearResident: "",
    yearUniversity: "",
    durationResident: "",
    durationUniversity: ""
  },
  documents: {
    generalDoctorTitle: false,
    degreeInAnesthesiology: false,
    curriculumVitae: false,
    exequatur: false,
    medicalCollegeCardAndCertification: false,
    letterOfRecommendationAnesthesiologists: false,
    presentScientificTopic: false,
    two2x2photos: false,
    inscription: false,
  },
  status: "active",
  recurringPayment: false,
  otherPostgraduate: [],
};

const initPostValidation = {
  names: true,
  familyName: true,
  identifier: true,
  registrationDate: true,
  cellphone: true,
  email: true,
  municipality: true,
  providence: true,
  region: true,
  postgraduateAnesthesia: {},
};

function _phoneUnTransform(phone) {
  return phone
    .replace("(", "")
    .replace(")", "")
    .replace("-", "")
    .replace(" ", "")
    .trim();
}

function Form() {
  const [form, setForm] = useState(initialState);
  const [isEdit, setEdit] = useState(false);
  const [postValidation, setPostValidation] = useState(initPostValidation);
  const [isSavingPost, setIsSavingPost] = useState(false);
  const [errorHttp, setErrorHttp] = useState({
    msg: "",
    variant: "",
  });
  const { memberId } = useParams();
  const navigate = useNavigate();
  function handleForm({ target: { value, name } }) {
    let data = {
      [name]: value,
    };
    if (name === "names") {
      data[name] = value;
      data.searchableName = value.toLowerCase();
    } else if (name === "familyName") {
      data[name] = value;
      data.searchableFamilyName = value.toLowerCase();
    }
    setForm({
      ...form,
      ...data,
    });
  }
  function handleFormCheckbox({ target: { checked, name } }) {
    let data = {
      [name]: checked,
    };
    setForm({
      ...form,
      ...data,
    });
  }
  function onCheckDocumentItem({ target: { checked, name } }) {
    setForm({
      ...form,
      documents: {
        ...form.documents,
        [name]: checked,
      },
    });
  }
  function handlePostgraduate({ target: { value, name } }) {
    setForm({
      ...form,
      postgraduateAnesthesia: {
        ...form.postgraduateAnesthesia,
        [name]: value,
      },
    });
  }
  function addOtherPostgraduate(item) {
    setForm({
      ...form,
      otherPostgraduate: [...form.otherPostgraduate, item],
    });
  }
  function handlerMunicipality(
    { value: municipality },
    { value: providence },
    { value: region }
  ) {
    setForm({
      ...form,
      municipality: municipality,
      providence: providence,
      region: region,
    });
  }
  async function saveMember() {
    setIsSavingPost(true);
    if (validatePost(form)) {
      try {
        form.names = transformText(form.names);
        form.familyName = transformText(form.familyName);
        form.searchableName = form.searchableName.trim();
        form.searchableFamilyName = form.searchableFamilyName.trim();
        form.email =
          form.email !== "" ? form.email.toLocaleLowerCase() : form.email;
        form.cellphone = _phoneUnTransform(form.cellphone);
        form.phone = _phoneUnTransform(form.phone);
        form.identifier = form.identifier.replace(/-/g, "").trim();

        let { body: payload } = await postMember(form);
        if (payload.success) {
          setErrorHttp({
            msg: "El usuario se guardo con exito!",
            variant: "success",
          });
          window.setTimeout(() => {
            navigate("/miembros");
          }, 990);
        } else {
          setErrorHttp({
            msg: "Hubo un error al intentar guardar, verifique si la cedula es unica",
            variant: "warning",
          });
        }
        scrollTop();
      } catch (error) {
        console.error("ERR ", error);
        setErrorHttp({
          msg: "Hubo un error al intentar guardar",
          variant: "error",
        });
        scrollTop();
      }
    }
    setIsSavingPost(false);
  }
  async function editMember() {
    setIsSavingPost(true);
    if (validatePost(form)) {
      try {
        form.names = transformText(form.names);
        form.familyName = transformText(form.familyName);
        form.searchableName = form.searchableName.trim();
        form.searchableFamilyName = form.searchableFamilyName.trim();
        form.email =
          form.email !== "" ? form.email.toLocaleLowerCase() : form.email;
        form.cellphone = _phoneUnTransform(form.cellphone);
        form.phone = _phoneUnTransform(form.phone);
        form.identifier = form.identifier
          .replace("-", "")
          .replace(" ", "")
          .trim();
        let { body: payload } = await putMember(form);
        if (payload.success) {
          setErrorHttp({
            msg: "El usuario se actualizado con éxito!",
            variant: "success",
          });
          window.setTimeout(() => {
            navigate("/miembros", { state: { member: form } });
          }, 800);
        } else {
          setErrorHttp({
            msg: "Hubo un error al intentar actulizar",
            variant: "warning",
          });
        }
        scrollTop();
      } catch (error) {
        console.log("Error al actualizar ", error);
      }
    }
    setIsSavingPost(false);
  }
  function validatePost(post) {
    try {
      let isValid = true;
      const fieldsToValidate = form.isUnCompleted
        ? ["names", "familyName", "identifier", "membershipToPay"]
        : [
            "names",
            "familyName",
            "identifier",
            "registrationDate",
            "cellphone",
            "email",
            "municipality",
            "providence",
            "region",
            "membershipToPay",
          ];
      const validation = fieldsToValidate.reduce((acc, key) => {
        let regOnlyNegativeAndPositveNumber = /^-?[0-9]+$/g;
        if (key === "email" && post[key] !== null && !isEmail(post[key])) {
          isValid = false;
          acc[key] = false;
        } else if (
          key === "membershipToPay" &&
          !regOnlyNegativeAndPositveNumber.test(post[key])
        ) {
          isValid = false;
          acc[key] = false;
        } else if (
          key === "identifier" &&
          post[key].replace(/-/g, "").trim().length < 11
        ) {
          isValid = false;
          acc[key] = false;
        }

        if (!post[key] && post[key] !== 0) {
          acc[key] = !!post[key];
          isValid = false;
        }

        return acc;
      }, {});

      let postgraduate = validatePostgraduateAnesthesia(
        form.postgraduateAnesthesia
      );
      setPostValidation({
        ...postValidation,
        ...validation,
        postgraduateAnesthesia: {
          ...postgraduate.postgraduateValidation,
        },
      });
      isValid = !isValid ? isValid : postgraduate.isValid;
      if (!isValid) {
        scrollTop();
      }
      return isValid;
    } catch (error) {
      console.error("Validation error ", error);
    }
  }

  function validatePostgraduateAnesthesia(postgraduateAnesthesia) {
    let inputsToValidate = [
      "hospital",
      "city",
      "country",
      "street",
      "yearResident",
      "yearUniversity",
    ];
    let isValid = true;
    let inputsValidated = inputsToValidate.reduce((inputs, input) => {
      inputs[input] = "";
      if (
        !form.isUnCompleted &&
        (postgraduateAnesthesia[input] === null ||
          postgraduateAnesthesia[input] === undefined)
      ) {
        isValid = false;
        inputs[input] = "El campo es requerido";
      }
      if (["city", "country"].includes(input)) {
        if (onlyNumber(postgraduateAnesthesia[input])) {
          isValid = false;
          inputs[input] = "Solo letras a-zA-Z";
        } else if (
          !form.isUnCompleted &&
          isEmpty(postgraduateAnesthesia[input])
        ) {
          isValid = false;
          inputs[input] = "El campo es requerido";
        }
      }

      if (input === "yearResident" || input === "yearUniversity") {
        if (
          !form.isUnCompleted &&
          postgraduateAnesthesia[input] !== "" &&
          (!onlyNumber(postgraduateAnesthesia[input]) ||
            (onlyNumber(postgraduateAnesthesia[input]) &&
              postgraduateAnesthesia[input].toString().length < 4))
        ) {
          isValid = false;
          inputs[input] = "Debe de tener 4 dígitos y solo números";
        } else if (
          !form.isUnCompleted &&
          postgraduateAnesthesia[input] === ""
        ) {
          isValid = false;
          inputs[input] = "El campo es requerido";
        }
      }

      return inputs;
    }, {});
    return { isValid, postgraduateValidation: inputsValidated };
  }

  function removeOtherPostgraduate(index) {
    let otherPostgraduate = [
      ...form.otherPostgraduate.slice(0, index),
      ...form.otherPostgraduate.slice(index + 1, form.otherPostgraduate.length),
    ];
    setForm({
      ...form,
      otherPostgraduate: [...otherPostgraduate],
    });
  }

  useEffect(() => {
    if (memberId) {
      async function loadMember() {
        try {
          let { body: member } = await getMemberByIdentifier(memberId);
          if (member.success) {
            setEdit(true);
            setForm({
              ...form,
              ...member.data,
            });
          }
        } catch (error) {
          console.error("Error to load member ", error);
        }
      }
      loadMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  return (
    <Container fixed maxWidth="md">
      <Paper>
        <Breadcrumbs aria-label="Breadcrumb">
          <Link color="inherit" href="/" onClick={() => {}}>
            Inicio
          </Link>
          <Typography color="textPrimary">Formulario miembro</Typography>
        </Breadcrumbs>
      </Paper>
      {!!errorHttp.msg && (
        <SnackbarContentWrapper
          variant={errorHttp.variant}
          message={errorHttp.msg}
        />
      )}
      <form noValidate autoComplete="off">
        <RowSettings
          form={form}
          handleFormCheckbox={handleFormCheckbox}
          handleForm={handleForm}
        />

        <PersonalInfo
          postValidation={postValidation}
          form={form}
          handleForm={handleForm}
          handlerMunicipality={handlerMunicipality}
          handleFormCheckbox={handleFormCheckbox}
        />
        <DocumentAdded
          checkDocumentItem={onCheckDocumentItem}
          {...form.documents}
        />
        <PostgraduateAnesthesia
          form={form.postgraduateAnesthesia}
          handleInputPostgraduate={handlePostgraduate}
          postgraduateAnesthesiaValidation={
            postValidation.postgraduateAnesthesia
          }
        />
        <OtherPostgraduate addOtherPostgraduate={addOtherPostgraduate} />
        <HospitalWorksDisplay
          otherPostgraduate={form.otherPostgraduate}
          removeOtherPostgraduate={removeOtherPostgraduate}
        />
        <ButtonSpace
          isEdit={isEdit}
          editMember={editMember}
          isSavingPost={isSavingPost}
          saveMember={saveMember}
        />
      </form>
    </Container>
  );
}

export default Form;
