export const initialState = {
    form: {
      services: [],
      quantity: 0,
      discount: 0,
      fetch: false,
      service: {},
      price: 0,
      compost: false,
      isPrint: false,
      voucherNumber: "",
      paymentType: "cash",
      notes: ""
    },
    invoice: {
      id: "",
      member: {},
      date: "",
      total: 0,
      services: [],
      status: "",
      paymentType: "",
      applyItebis: false,
      currencyType: "DOP",
      currencyRate: 1
    },
    status: "",
    isFetchMembers: false,
    isSaving: false,
    isPrint: false,
    httpMsg: {
      variant: '',
      msg: ''
    },
};

export function reducer(state, action) {
    switch (action.type) {
      case "change_print":
          return {
              ...state,
              isPrint: action.value
          }
      case "fetch_services":
        return {
          ...state,
          form: {
            ...state.form,
            fetch: true,
          },
        };
      case "fetched_services":
        return {
          ...state,
          form: {
            ...state.form,
            fetch: false,
            services: action.payload,
          },
        };
      case "change_prop_form":
        return {
          ...state,
          form: {
            ...state.form,
            [action.name]: action.value,
          },
        };
      case "change_form_compost":
          return {
              ...state,
              form: {
                ...state.form,
                compost: action.value 
              },
              httpMsg: {
                variant: '',
                msg: ''
              }
          }
      case "reset_quantity":
        return {
          ...state,
          form: {
            ...state.form,
            quantity: 0,
            discount: 0
          },
        };
      case "change_prop_invoice":
        return {
          ...state,
          invoice: {
            ...state.invoice,
            [action.name]:
              action.name === "services"
                ? [...state.invoice.services, action.value]
                : action.value,
          },
        };
      case "remove_invoice_services":
        let indexOf = state.invoice.services
          .map((service) => service.value)
          .indexOf(action.value);
        return {
          ...state,
          invoice: {
            ...state.invoice,
            services: [
              ...state.invoice.services.slice(0, indexOf),
              ...state.invoice.services.slice(
                indexOf + 1,
                state.invoice.services.length
              ),
            ],
          },
        };
      case "save_invoice":
        return {
            ...state,
            invoice: {
                ...state.invoice
            },
            status: "save",
            httpMsg: {
                variant: 'success',
                msg: 'La factura se a guardado con exito.'
              }
        }
      case "modified_invoice_services":
        let index = state.invoice.services
          .map((service) => service.value)
          .indexOf(action.service.value);
        return {
          ...state,
          invoice: {
            ...state.invoice,
            services: [
              ...state.invoice.services.slice(0, index),
              action.service,
              ...state.invoice.services.slice(
                index + 1,
                state.invoice.services.length
              ),
            ],
          },
        };
      case "clean_form":
        return {
          ...state,
          form: {
            ...state.form,
            quantity: 0,
            price: 0,
            service: {},
            fetch: false,
            notes: "",
            isPrint: false
          },
          invoice: {
            ...initialState.invoice,
            date: action.date
          },
          status: "new",
          httpMsg: {
            variant: '',
            msg: ''
          }
        };
      case "invoice_save_failed":
          return {}
      default:
        return state;
    }
}
