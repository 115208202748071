import React, { useState } from "react";
import { string, func } from "prop-types";
import { 
  Button, 
  TextField, 
  Icon, 
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  CircularProgress
} from "@mui/material";
import { FILTER_OPTIONS } from "../../const";
import "./searchBox.css";


function SearchBox({ getByName, placeholder, searchLoading, extraFilters = true }) {
  const [searchText, setSearchText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [filterOption, setFilterOption] = useState("general");

  function onChangeTextSearch({ target: { value } }) {
    if (/-|_|\+/g.test(value)) {
      setErrorMessage("No se permite -, _ o +");
    } else if (errorMessage !== "") {
      setErrorMessage("")
    }
    setSearchText(value);
  }

  function clearSearchField() {
    setSearchText("");
    getByName("");
  }

  function handelFilterSearch(e) {
    const { value } = e.target;
    setFilterOption(value);
  }

  return (
    <div htmlFor="search" className="top_row_first_">
      <TextField
        className="input_search"
        id="search"
        label="Busqueda"
        value={searchText}
        onChange={onChangeTextSearch}
        name="name"
        variant="outlined"
        placeholder={filterOption === FILTER_OPTIONS.schoolCode ? "Código de colegio" : placeholder}
        error={errorMessage !== ""}
        helperText={errorMessage}
        InputProps={{ 
          //"data-testid": "search-input",
          endAdornment: (
            <InputAdornment position="end">
              {!!searchText ? (
                 <Button
                   aria-label="clean search button"
                   onClick={clearSearchField}
                   data-testid="cleanButton"
                   color="secondary"
                 >
                   Limpiar
                 </Button>
               ) : null}
               {!searchLoading ? ( 
                 <Button
                     aria-label="search button"
                     name="Buscar"
                     variant="contained"
                     onClick={() => errorMessage === "" && getByName(searchText, filterOption)}
                     data-testid="searchButton"
                     disabled={errorMessage !== ""}
                   >
                     <Icon>search</Icon>
                   </Button>
                   ) : (
                 <CircularProgress />)
              }                        
            </InputAdornment>
          )
        }}
      />

      <FormControl className="filter_group">
      <FormLabel>Filtro de busquedas</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={filterOption}
        onChange={handelFilterSearch}
      >
        <FormControlLabel value={FILTER_OPTIONS.general} control={<Radio />} label="General" />
        {extraFilters ? <FormControlLabel value={FILTER_OPTIONS.schoolCode} control={<Radio />} label="Código de colegio" /> : null }
      </RadioGroup>
    </FormControl>    
    </div>
  );
}

SearchBox.propTypes = {
  getByName: func,
  searchText: string,
};

export default SearchBox;
