import React, { useState } from "react";
import { RadioButton } from "../../components";
import {
  Divider,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import logoAnestiologia from "../../assests/anestesiologia.png";
import { SnackbarContentWrapper } from "../../components";
import { sendMessage } from "../../services/menssagingAPI";
import "./messaging.css";

function Form() {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [memberStatus, setMemberStatus] = useState("all");
  const [msgSend, setMsgSend] = useState("");
  const [isSending, setIsSending] = useState(false);

  function handlerMessage({ target: { value } }) {
    if (msgSend) {
      setMsgSend("");
    }
    setMessage(value);
  }

  function handlerSubject({ target: { value } }) {
    if (msgSend) {
      setMsgSend("");
    }
    setSubject(value);
  }

  function handlerMemberStatus({ target: { value } }) {
    if (msgSend) {
      setMsgSend("");
    }
    setMemberStatus(value);
  }

  async function handlersSendMessage() {
    try {
      if (!!message) {
        setIsSending(true);
        const { body } = await sendMessage(
          message.replace(/\n/g, "<br>\n"),
          memberStatus,
          subject
        );
        setMsgSend(body.message);
        setMessage("");
        setSubject("");
      }
    } catch (error) {
    } finally {
      setIsSending(false);
    }
  }

  function handlerCloseNotification() {
    setIsSending(false);
  }

  return (
    <>
      {msgSend !== "" && (
        <SnackbarContentWrapper
          variant="success"
          message={`Mensaje enviados ${msgSend}`}
          onClose={handlerCloseNotification}
        />
      )}
      <div className="messaging__container">
        <div className="messaging__preview-container">
          <h2 className="messaging__title">Vista Previa</h2>
          <div className="messaging__preview">
            <img
              className="messaging__log_sociedad"
              alt="logo de sociedad"
              src={logoAnestiologia}
            />
            <p className="messaging__preview-text">{message}</p>
          </div>
        </div>
        <Divider orientation="vertical" variant="middle" />
        <div className="messaging__form">
          <div className="messaging__form-top">
            <FormControl component="fieldset">
              <FormLabel component="legend">Enviar a Miembros</FormLabel>
              <RadioGroup
                defaultValue="all"
                aria-label="estados"
                name="estados-radios"
                value={memberStatus}
                onChange={handlerMemberStatus}
              >
                <FormControlLabel
                  value="all"
                  control={<RadioButton />}
                  label="Todos"
                />
                <FormControlLabel
                  value="active"
                  control={<RadioButton />}
                  label="Activo"
                />
                <FormControlLabel
                  value="inactive"
                  control={<RadioButton />}
                  label="Desactivado"
                />
              </RadioGroup>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handlersSendMessage}
              classes={{ root: "messaging__send-button" }}
              disabled={isSending}
            >
              Enviar mensaje
            </Button>
          </div>
          <Divider />
          <h2 className="messaging__title">Titulo</h2>
          <input
            type="text"
            onChange={handlerSubject}
            className="subject_text"
          />
          <h2 className="messaging__title">Cuerpo del mensaje</h2>
          <textarea
            className="message__body"
            cols="90"
            rows="20"
            onChange={handlerMessage}
          />
        </div>
      </div>
    </>
  );
}

export default Form;
