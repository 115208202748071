/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { isEmpty } from 'lodash';
import {
  getMember,
  deleteMember,
  //getMemberByFilter,
  //getMemberByIdentifier,
  getCustomersMetadata,
  getMemberByIdentifier,
  getLastMember,
  getMemberBySchoolCode
} from "../../../services/memberAPI";
import {Container} from '@mui/material';
import { SearchBox, AlertDialog, SnackbarContentWrapper } from "../../../components";
import TableMember from "../../../components/memberList/tableMember/TableMember";
import LastUpdated from "../../../components/memberList/lastUpdated/LastUpdated";
import StatusTabs from "../../../components/memberList/statuFilters/StatusTabs";
import TopRow from "../../../components/memberList/topRow/TopRow";
import { onlyNumber } from "../../../utils/validators";
import { FILTER_OPTIONS } from "../../../const";

const ITEM_PEER_PAGE = 10;

let initializeMetadata = {
  totalInactive: 0,
  totalActive: 0,
  totalTableRow: 0,
  loading: false,
};

const TYPE_OF_MEMBER = {
  member: 0,
  aspiranting: 1
}
function MembersList() {
  const [members, setMembers] = useState([]);
  const [lastMemberUpdated, setLastMemberUpdated] = useState({});
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [httpStatus, setHttpStatus] = useState(null);
  const [memberStatusDisplay, setStatus] = useState("active");
  const [pagination, setPagination] = useState(0);
  const [metadata, setMetadata] = useState(initializeMetadata);
  const [searchLoading, setSearchLoading] = useState(false);
  const [ memberStage, setMemberStage ] = useState(null);
  const [tabIndex, setTabIndex] = useState(TYPE_OF_MEMBER.member);
  
  async function getByName(textToSearch, filterOption) {
    try {
      setName(textToSearch);
      setSearchLoading(true);
      if (!!textToSearch) {
          let payload;
          if (onlyNumber(textToSearch)) {
            let response = await (FILTER_OPTIONS.schoolCode === filterOption ? 
              getMemberBySchoolCode(textToSearch) 
            : getMemberByIdentifier(textToSearch))
            payload = response.body;
          } else {
            let response = await getMember(memberStatusDisplay, 0, ITEM_PEER_PAGE, textToSearch)
            payload = response.body;
          }
          
        if (payload.success) {
          setPagination(0);
          const data = Array.isArray(payload.data) ? payload.data : [payload.data]
          setMembers(data);
        } else {
          setHttpStatus({type: 'warning', msg: `No se encontro ningun miembro(s)... ${textToSearch}`})
        }
      } else {
        let { body: payload } = await getMember(
          memberStatusDisplay,
          0,
          ITEM_PEER_PAGE,
          "",
          ""
        );
        if (payload.success) {
          setMembers(payload.data);
          setPagination(0);
        }        
      }
      setSearchLoading(false);
    } catch (error) {
      setSearchLoading(false);
      setHttpStatus({type: 'error', msg: 'Hubo un error al intentar buscar...'})
    }
  }

  function handleChangePage(e, page) {    
    setPagination(page);    
  }

  async function fillTable(memberStatusDisplay) {
    setLoading(true);
    try {
      let { body: membersFetched } = await getMember(
        memberStatusDisplay,
        pagination,
        ITEM_PEER_PAGE,
        name,
        null,
        false,
        tabIndex === TYPE_OF_MEMBER.member ? 'member' : 'aspiring'
      );
      
      if (membersFetched.success) {
        setMembers(membersFetched.data);
      } else {
        setMembers([]);
      }
    } catch (error) {
      setHttpStatus({ type: 'error', msg: "Error al cargar los miembros"});
    }
    setLoading(false);
  }

  function loadMembersByStatus(memberStatusDisplay) {
    setStatus(memberStatusDisplay);
    setPagination(0);
  }

  function stageDeleteMember(member) {
    setMemberStage(member);
  }

  async function sendMemberToDelete() {
    try {
      if (setHttpStatus != null) {
        setHttpStatus(null);
      }
      setLoading(true);
      const member = await deleteMember({_id: memberStage._id});
      fillTable(memberStatusDisplay);
      setMemberStage(null);
      if (member.body.success) {
        setHttpStatus({ type: "success", msg: "El miembro fue eliminado."});
        
        setMetadata((prev) => {
          return {
          ...prev,
          totalInactive: memberStatusDisplay === 'inactive' ? prev.totalInactive - 1 : prev.totalInactive,
          totalActive: memberStatusDisplay === 'active' ? prev.totalActive - 1 : prev.totalActive,
          totalTableRow: prev.totalTableRow - 1,
        }});
      }
    } catch(err) {
      setLoading(false);
      setMemberStage(null);      
      setHttpStatus({ type: "error", msg: "Error al intentar eliminar un miembro."});
    }    
  }

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
    setPagination(0)
  }

  useEffect(() => {
    (async () => {
      try {
        setMetadata({
          ...metadata,
          loading: true,
        });
        
        let { body: member } = await getLastMember();
        
        if (member.success) {
          setLastMemberUpdated(member.data);
        }
      
      } catch (err) {
        setMetadata({
          ...metadata,
          loading: false,
        });
      }
    })();
  }, []);

  useEffect(() => {
     (async () => {
      let { body } = await getCustomersMetadata(tabIndex === TYPE_OF_MEMBER.member ? 'member' : 'aspiring');

      if (!body.success) {
        body.totalActiveMember = "?";
        body.totalInactiveMember = "?";
      }
      setMetadata((prev) => ({
        ...prev,
        loading: false,
        totalInactive: body.totalInactiveMember,
        totalActive: body.totalActiveMember,
        totalTableRow: body.totalMembers,
      }));
     })()
  },[tabIndex])

  useEffect(() => {
    fillTable(memberStatusDisplay);
  }, [pagination, memberStatusDisplay, tabIndex]);
  
  return (
    <Container maxWidth="xl">
      {
        httpStatus != null ? (
              <SnackbarContentWrapper
                open={httpStatus != null}
                variant={httpStatus.type}
                message={httpStatus.msg}
                onSetHttp={setHttpStatus}
                onClose={() => setHttpStatus(null)}
              />
            ) 
        : null
      }
      <TopRow />
      <div>
          {memberStage ? (
            <AlertDialog
              type="delete"
              isOpen={true}
              title="Eliminar miembro."
              isLoading={loading}
              message={<div>
                <h4>Esta acción es permanente.</h4>
                <p>{`Eliminar a ${memberStage.names} ${memberStage.familyName}`}</p>
                <p>{`Cedula: ${memberStage.identifier}`}</p>
               </div>
              }
              onCancel={() => setMemberStage(null)}
              onOk={sendMemberToDelete}
            />) : null
          }
       
          <SearchBox
            searchLoading={searchLoading}
            getByName={getByName}
            placeholder="Cédula, Nombres o Apellidos(mínimo 3 caracteres)"
          />        
        
       
        <StatusTabs
          loadMembersByStatus={loadMembersByStatus}
          memberStatusDisplay={memberStatusDisplay}
          metadata={metadata}
          tabIndex={tabIndex}
          handleChangeTab={handleChangeTab}
        />
        
        {!isEmpty(lastMemberUpdated) ? (
          <LastUpdated
            member={lastMemberUpdated}
          />
        ) : null }

        <TableMember
          loading={loading}
          membersArray={members}
          handleChangePage={handleChangePage}
          page={pagination}
          stageDeleteMember={stageDeleteMember}
          totalMember={ memberStatusDisplay === 'active' ? metadata.totalActive : metadata.totalInactive }
        />  
           
      </div>
      </Container>
  );
}

export default MembersList;
