import React from "react";
//import MaskedInput from 'react-text-mask';
import { IMaskInput } from "react-imask";

export const TextMaskPhone = React.forwardRef(function TextMaskPhone(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask="(000)-000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      placeholderChar={"\u2000"}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      unmask
    />
  );
});

export const TextMaskIdentity = React.forwardRef(function TextMaskIdentity(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask="000-0000000-0"
      definitions={{
        "#": /[1-9]/,
      }}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      placeholderChar={"\u2000"}
      unmask
    />
  );
});
