function reducer(state, action) {
    switch (action.type) {
        case "change_prop":
            return {
              ...state,
              [action.name]: action.value
            }
        case "companies_request":
            return {
                ...state,
                isFetchCompanies: true
            };
        case "companies_success":
            return {
                ...state,
                isFetchCompanies: false
            }
        case "companies_failure":
            return {
                ...state,
                isFetchCompanies: false,
                httpMsg: {
                    variant: 'error',
                    msg: action.payload.msg
                }
            }
        case "change_form_value":
            return {
                ...state,
                form: {
                    ...state.form,
                    [action.name]: action.value
                }
            }
        case "set_new_invoice":
            return {
                ...state,
                ...action.payload
            }
        case "change_invoice":
          return {
            ...state,
            invoice: {
              ...state.invoice,
              [action.name]: action.name === "services"
              ? [...state.invoice.services, action.value]
              : action.value,
            }
          }
        case "reset_quantity":
          return {
            ...state,
            quantity: 0,
            discount: 0
          }
        case "save_invoice":
          return {
            ...state,
            status: "save",
            httpMsg: {
                variant: 'success',
                msg: 'La factura se a guardado con éxito.'
              }
          }
        case "remove_invoice_services":
            let indexOf = state.invoice.services
              .map((service) => service.value)
              .indexOf(action.value);

            return {
              ...state,
              invoice: {
                ...state.invoice,
                services: [
                  ...state.invoice.services.slice(0, indexOf),
                  ...state.invoice.services.slice(
                    indexOf + 1,
                    state.invoice.services.length
                  ),
                ],
              },
            };
        case "modified_invoice_services":
           let index = state.invoice.services
                .map((service) => service.value)
                .indexOf(action.service.value);
           return {
             ...state,
             invoice: {
               ...state.invoice,
               services: [
                 ...state.invoice.services.slice(0, index),
                 action.service,
                 ...state.invoice.services.slice(
                   index + 1,
                   state.invoice.services.length
                 ),
               ],
             },
           };
        case "fetched_services":
          return {
            ...state,
            form: {
              ...state.form,
              isFetchingServices: false,
              services: action.payload,
            },
          };
        case "add_member_reference":
          return {
            ...state,
            invoice: {
              ...state.invoice,
              referenceMembers: [
                ...state.invoice.referenceMembers, 
                { service: action.payload.service, 
                  members: [action.payload.member] 
                }
              ]
            }
          }
        case "add_member_reference_service":
          const indexReference = state.invoice.referenceMembers.findIndex(item => item.service === action.payload.service)
          return {
            ...state,
            invoice: {
              ...state.invoice,
              referenceMembers: [
                ...state.invoice.referenceMembers.slice(0, indexReference),
                {
                  service: action.payload.service,
                  members: [ ...state.invoice.referenceMembers[indexReference].members, action.payload.member ]
                },
                ...state.invoice.referenceMembers.slice(indexReference + 1, state.invoice.referenceMembers.length)
              ]
            }
          }
        case "remove_member_reference_service":
          const indexRef = state.invoice.referenceMembers.findIndex(item => item.service === action.payload.service);
          const memberIdxRef = state.invoice.referenceMembers[indexRef].members.findIndex(item => item.value === action.payload.member.value);
          return {
            ...state,
            invoice: {
              ...state.invoice,
              referenceMembers: state.invoice.referenceMembers[indexRef].members.length === 1 ?
              [
                ...state.invoice.referenceMembers.slice(0, indexRef),
                ...state.invoice.referenceMembers.slice(indexRef + 1, state.invoice.referenceMembers.length)
              ] :
              [
                ...state.invoice.referenceMembers.slice(0, indexRef),
                {
                  service: state.invoice.referenceMembers[indexRef].service,
                  members: [
                    ...state.invoice.referenceMembers[indexRef].members.slice(0, memberIdxRef),
                    ...state.invoice.referenceMembers[indexRef].members.slice(memberIdxRef + 1, state.invoice.referenceMembers[indexRef].members.length)
                  ]
                },
                ...state.invoice.referenceMembers.slice(indexRef + 1, state.invoice.referenceMembers.length)
              ]
            }
          }
          default: 
            return state;
    }
}

export default reducer