import React from "react";
import { TextField } from "@mui/material";
import clsx from "clsx";
import styles from "./postgraduateAnesthesia.module.scss";

function PostgraduateAnesthesia({
  form: { hospital, city, country, street, yearResident, yearUniversity, durationResident, durationUniversity },
  handleInputPostgraduate,
  postgraduateAnesthesiaValidation,
}) {
  /*
  const [ otherPostgraduate, setOtherPostgraduate ] = useState(initialState)
    function handleOtherPostgraduateInput({target: {name, value }}) {
      setOtherPostgraduate({
        ...otherPostgraduate,
        [name]: value
      })
    }*/
  return (
    <div className="">
      <h4>Pos-grado Principal</h4>
      <div className="posgrade_form">
        <TextField
          required
          id="hospital_name"
          label="Hospital"
          name="hospital"
          margin="normal"
          value={hospital}
          onChange={handleInputPostgraduate}
          fullWidth
          error={!!postgraduateAnesthesiaValidation.hospital}
          helperText={postgraduateAnesthesiaValidation.hospital}
          variant="standard"
        />
        <TextField
          required
          id="hospital_street"
          label="Calle"
          margin="normal"
          name="street"
          value={street}
          onChange={handleInputPostgraduate}
          fullWidth
          error={!!postgraduateAnesthesiaValidation.street}
          helperText={postgraduateAnesthesiaValidation.street}
          variant="standard"
        />
        <div className={styles.inputContainer}>
          <TextField
            required
            id="hospital_city"
            label="Ciudad"
            margin="normal"
            name="city"
            value={city}
            onChange={handleInputPostgraduate}
            fullWidth
            className={clsx(styles.inputMedium, styles.inputRightSpace)}
            error={!!postgraduateAnesthesiaValidation.city}
            helperText={postgraduateAnesthesiaValidation.city}
            variant="standard"
          />
          <TextField
            required
            id="hospital_country"
            label="Pais"
            margin="normal"
            name="country"
            value={country}
            onChange={handleInputPostgraduate}
            fullWidth
            className={styles.inputMedium}
            error={!!postgraduateAnesthesiaValidation.country}
            helperText={postgraduateAnesthesiaValidation.country}
            variant="standard"
          />
        </div>
        <div className={styles.inputContainer}>
          <TextField
            required
            id="hospital_year"
            label="Año de residencia"
            margin="normal"
            name="yearResident"
            value={yearResident}
            onChange={handleInputPostgraduate}
            fullWidth
            maxLength="4"
            minLength="4"
            className={clsx(styles.inputMedium, styles.inputRightSpace)}
            error={!!postgraduateAnesthesiaValidation.yearResident}
            helperText={postgraduateAnesthesiaValidation.yearResident}
            variant="standard"
          />
          <TextField
            required
            id="hospital_year_university"
            label="Año de la universidad"
            margin="normal"
            maxLength="4"
            minLength="4"
            name="yearUniversity"
            value={yearUniversity}
            onChange={handleInputPostgraduate}
            fullWidth
            className={styles.inputMedium}
            error={!!postgraduateAnesthesiaValidation.yearUniversity}
            helperText={postgraduateAnesthesiaValidation.yearUniversity}
            variant="standard"
          />
        </div>
        <div className={styles.inputContainer}>
          <TextField
            required
            id="hospital_year_duration"
            label="Año de duración en residencia"
            margin="normal"
            name="durationResident"
            value={durationResident}
            onChange={handleInputPostgraduate}
            fullWidth
            maxLength="2"
            minLength="1"
            className={clsx(styles.inputMedium, styles.inputRightSpace)}
            error={!!postgraduateAnesthesiaValidation.durationResident}
            helperText={postgraduateAnesthesiaValidation.durationResident}
            variant="standard"
          />
          <TextField
            required
            id="hospital_year_university_duration"
            label="Año de duración en universidad"
            margin="normal"
            maxLength="2"
            minLength="1"
            name="durationUniversity"
            value={durationUniversity}
            onChange={handleInputPostgraduate}
            fullWidth
            className={styles.inputMedium}
            error={!!postgraduateAnesthesiaValidation.durationUniversity}
            helperText={postgraduateAnesthesiaValidation.durationUniversity}
            variant="standard"
          />
        </div>
      </div>
    </div>
  );
}

export default PostgraduateAnesthesia;
