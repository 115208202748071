import React from "react";
import { Container, Box, Icon } from "@mui/material";

import { SnackbarContentWrapper, ButtonLink } from "../../components";
import InvoiceOwner from './components/InvoiceOwner/InvoiceOwner';
import ServicesForm from "./components/ServicesForm/ServicesForm";
import ServicesDetails from "../../components/paymentManagment/ServicesDetails/ServicesDetails";
import MemberItemTemplate from "./MemberItemTemplate";
import FormSettings from "../../components/paymentManagment/FormSettings/FormSettings";

import "./chargeManagement.css";

const stackOfMessage = {
  compost: {
    msg:
      "Para poder consumir los servicios se abonaran tres coutas de membresia, puede agregar mas coutas si desea.",
    variant: "warning",
  },
  serverError: {
    msg: "Hubo un error al guardar la factura",
    variant: "warning",
  },
  serverSuccess: {
    msg: "Facura guardada con exito",
    variant: "success",
  },
  empty: {
    msg: "",
  },
};


function Form({ isDisabled, ...props }) {
  let message = !!props.msg ? stackOfMessage[props.msg] : stackOfMessage.empty;
  return (
    <Box displayPrint="none">
      <Container displayPrint="none">
        <div>
          <header className="header__charge">
            <h1>Cargo de servicios</h1>
            <ButtonLink to="/facturas">
              <Icon>listAlt</Icon>
              <span>Facturas</span>
            </ButtonLink>
          </header>
          <main>
            {!!message.msg && (
              <SnackbarContentWrapper
                variant={message.variant}
                message={message.msg}
              />
            )}
            <FormSettings
               {...props}
               isDisabled={isDisabled}
               canPrint={props.canPrint}
               formId={props.state.invoice.id}
               status={props.state.invoice.status}
            />
            <InvoiceOwner 
              ownerType="Miembro"
              name="member"
              fetchItems={props.fetchMember}
              onDropDownChange={props.onDropDownChange}
              value={props.state.invoice.member}
              isDisabled={isDisabled || props.loading}
              formatOptionLabel={MemberItemTemplate}
            />
            <ServicesForm
              {...props}
              //onAddService={onAddService}
              //onChangeInput={onChangeInput}
              //onDropDownChange={onDropDownChange}
              //state={state}
              //validState={validState}
              isDisabled={isDisabled || props.loading}
            />
            <ServicesDetails
              {...props}
              compost={props.state.form.compost}
              services={props.state.invoice.services}
              //services={state.invoice.services}
              //removeServices={removeServices}
              //decreaseServiceQuantity={decreaseServiceQuantity}
              isDisabled={isDisabled || props.loading}
            />
          </main>
        </div>
      </Container>
    </Box>
  );
}

export default Form;
