import * as React from "react";
import SnackbarContentWrapper from "./SnackbarContentWrapper";
import CssBaseline from "@mui/material/CssBaseline";
import AppRouter from "../Router/Main";
import { useToast, useToastSetter } from "../context/ToastContext"
import "./App.css";

function App() {
  const toast = useToast();
  const setToast = useToastSetter();
  return (
    <>
      <CssBaseline />
      <AppRouter />
      <SnackbarContentWrapper/>
    </>
  );
}

export default App;
