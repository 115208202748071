import React, { useState, useEffect } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { ButtonLink, SearchBox } from "../../components";
import TableProvider from "../../components/CommercialProvider/Table";
import API from "../../config/api";
import { useToastSetter } from "../../context/ToastContext";
import styles from './commercialProvider.module.scss';

const List = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [providers, setProviders] = useState([]);
    const setToast = useToastSetter();

    const getByName = () => {}

    useEffect(() => {
       (async () => {
        setIsLoading(true);
        try {
            const payload = await API.get('commercial_supplier/all/true');
            setProviders(payload);
        } catch(error) {
            setToast({
                open: true,
                message: 'Los proveedores no se pueden cargar',
                variant: 'error'
            });
        } finally {
            setIsLoading(false);
        }
       })();
    },[]);

    return (
        <>
          <div className={styles.btnNewContainer}>
          <ButtonLink to='/proveedor/nuevo'>
            <AddCircleOutlineOutlinedIcon/>Nuevo proveedor  
          </ButtonLink>
          </div>
           <div className={styles.searchBarContainer}>
              <SearchBox getByName={getByName} placeholder="Nombre del proveedor" extraFilters={false} />
           </div>
           <TableProvider loading={isLoading} providers={providers}/>
        </>
    )
}

export default List;
