import API from "../config/api";

const BASE = "company";

export function saveCompany(company) {
    return API.post(`${BASE}/save`, company);
}

export function getCompany(id) {
    return API.get(`${BASE}/id/${id}`);
}

export function getCompanies(text, isActive = true) {
    let query = `?isActive=${isActive}`;

    if (!!text) {
        query += `&text=${text}`;
    }

    return API.get(`${BASE}/all${query}`);
}

export function updateCompany(company) {
    return API.put(`${BASE}/update`, company)
}