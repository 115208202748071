import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  TextField,
  Paper,
  Breadcrumbs,
  Typography,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
  Icon,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import API from "../../config/api";
import { SnackbarContentWrapper } from "../../components";
import { validateForm, getMonthName } from "../../utils/utils";
import styles from "./taxReceiptSequence.module.scss";

const formData = {
  description: "",
  taxNumber: "",
  startSecuence: "",
  endSecuence: "",
  endMonth: 12,
  endYear: 0,
  isActive: true
};

const validFormInitializer = {
  description: true,
  taxNumber: true,
  startSecuence: true,
  endSecuence: true,
};

const TaxReceiptSequence = () => {
  const [form, setForm] = useState(formData);
  const [taxReceiptSequences, setTaxReceiptSequences] = useState([]);
  const [msgHttp, setMsgHttp] = useState({
    msg: "",
    variant: "",
  });
  const [validForm, setValidForm] = useState(validFormInitializer);
  const [loading, setLoading] = useState(false);
  const [failLoadingRNC, setFailLoadingRNC] = useState(false);

  const navigate = useNavigate();
  
  function onChangeFormInputs({ target: { name, value } }) {
    setForm({
      ...form,
      [name]: value,
    });
  }

  const onSave = async () => {
    setLoading(true);
    const validate = validateForm(form, 
        ['description', 'taxNumber', 'startSecuence', 'endSecuence']);

        if (validate.isValid) {
          try {            
            await API.post('tax_receipt_sequence', 
              {
                ...form,
                currentNumber: form.startSecuence
              });
            
            setForm(formData);
            setLoading(false);
          } catch(error) {
            setLoading(false);
          }
           
        } else {
            setMsgHttp({
              msg: "Hubo un error al intentar guardar",
              variant: "warning",
            });
            setValidForm(validate.validatedState);
            setLoading(false);
          }
  }

  const onHandleDate = (e) => {
    setForm(prev => ({...prev, endMonth: e["$M"], endYear: e["$y"] }))
  }

  useEffect(() => {
    (async ()=> {
      try {
        const payload = await API.get('tax_receipt_sequence');
        setTaxReceiptSequences(payload);
      } catch(error) {
        setFailLoadingRNC(true)
      }
    })();
  },[]);

  if (failLoadingRNC) {
    return <h4>Hubo un error al comunicarce con el servidor, intente de nuevo.</h4>
  }

  return (
    <Container maxWidth="sm">
      <Paper>
        <Breadcrumbs aria-label="Breadcrumb">
          <Link color="inherit">Inicio</Link>
          <Typography color="textPrimary">
            Formulario de comprobantes
          </Typography>
        </Breadcrumbs>
      </Paper>

      {!!msgHttp.msg && (
        <SnackbarContentWrapper
          variant={msgHttp.variant}
          message={msgHttp.msg}
          show={!!msgHttp.msg}
        />
      )}

      <form className={styles.form} noValidate autoComplete="off" onSubmit={onSave}>
        <TextField
          id="description"
          label="Desripción"
          value={form.description}
          name="description"
          onChange={onChangeFormInputs}
          error={!validForm.description}
          variant="standard"
          fullWidth
        />
        <div className={styles.taxNumber}>
          <TextField
            id="taxNumber"
            label="Serie/Tipo de comprobante"
            value={form.taxNumber}
            name="taxNumber"
            onChange={onChangeFormInputs}
            error={!validForm.taxNumber}
            variant="standard"
            className={styles.taxNumberInput}
          />
          <div className={styles.wrapperCurrentNumber}>
            <label>Numero actual</label>
            <span>0</span>
          </div>
        </div>
        <TextField
          id="startSecuence"
          label="Inicio de secuencia"
          value={form.startSecuence}
          name="startSecuence"
          onChange={onChangeFormInputs}
          error={!validForm.startSecuence}
          variant="standard"
          className={styles.inputSpace}
        />
        <TextField
          id="endSecuence"
          label="Fin de secuencia"
          value={form.endSecuence}
          name="endSecuence"
          onChange={onChangeFormInputs}
          error={!validForm.endSecuence}
          variant="standard"
          className={styles.endSecuence}
        />
        <div className={styles.pickerContainer}>
          <label>Fecha de vencimiento</label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker onChange={onHandleDate}/>
        </LocalizationProvider>
        </div>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={loading}
            className={styles.btnSubmit}
          >
            {!loading ? 'Guardar' : <CircularProgress color="inherit"/> }
          </Button>
      </form>

      {
        
            <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell align="left">Descripción</TableCell>
                <TableCell align="left">Numero</TableCell>
                <TableCell align="left">Numero actual</TableCell>
                <TableCell align="left">Inicio</TableCell>
                <TableCell align="left">Fin</TableCell>
                <TableCell align="left">Exp</TableCell>
                <TableCell align="left">Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {taxReceiptSequences.map((numbers, index) => {
              return ( 
                <TableRow key={`taxNumber-${index}`}>
                 <TableCell align="left">{index + 1}</TableCell>
                 <TableCell align="left">{numbers.description}</TableCell>
                 <TableCell align="left">{numbers.taxNumber}</TableCell>
                 <TableCell align="left">{numbers.currentNumber}</TableCell>
                 <TableCell align="left">{numbers.startSecuence}</TableCell>
                 <TableCell align="left">{numbers.endSecuence}</TableCell>
                 <TableCell align="left">{getMonthName(numbers.endMonth)}/{numbers.endYear}</TableCell>
                 <TableCell align="left">{numbers.isActive ? 'Activo' : 'Desactivado'}</TableCell>
                </TableRow>
               )
              })
            }
            </TableBody>
            </Table>
        
      }
    </Container>
  );
};

export default TaxReceiptSequence;
