import React, { useState } from "react";
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Button,
  FormHelperText,
} from "@mui/material";
import clsx from "clsx";
import { isEmpty } from "../../../utils/validators";
import { TextMaskPhone } from "../../MaskInput/MaskInput";
import styles from "./postgraduateAnesthesia.module.scss";

const initialState = {
  name: "",
  hospital: "",
  city: "",
  type: "",
  phone: "",
};

function OtherPostgraduate({ addOtherPostgraduate }) {
  const [otherPostgraduate, setOtherPostgraduate] = useState(initialState);
  const [formValidation, setFormValidation] = useState({});

  function handleSelect() {
    try {
      if (validations(otherPostgraduate)) {
        addOtherPostgraduate(otherPostgraduate);
        setOtherPostgraduate({ ...initialState });
      }
    } catch (error) {
      console.log("ERROR ", error);
    }
  }

  function validations(form) {
    let fieldToValidate = ["name", "hospital", "city", "type", "phone"];
    let isValid = true;
    let formValidated = fieldToValidate.reduce((inputs, input) => {
      inputs[input] = "";
      if (isEmpty(form[input])) {
        isValid = false;
        inputs[input] = "El campo es requerido.";
      }
      return inputs;
    }, {});
    setFormValidation(formValidated);
    return isValid;
  }

  function handleOtherPostgraduateInput({ target: { name, value } }) {
    setOtherPostgraduate({
      ...otherPostgraduate,
      [name]: value,
    });
  }
  return (
    <div className="">
      <p>Otro pos-grado</p>
      <div className="posgrade_form">
        <div className={styles.inputContainer}>
          <TextField
            required
            label="Cual?"
            margin="normal"
            fullWidth
            value={otherPostgraduate.name}
            name="name"
            onChange={handleOtherPostgraduateInput}
            className={clsx(
              styles.inputMedium,
              styles.inputNoVerticalSpace,
              styles.inputRightSpace
            )}
            error={!!formValidation.name}
            helperText={formValidation.name}
            variant="standard"
          />
          <FormControl fullWidth className={styles.inputSmall}>
            <InputLabel htmlFor="type-simple">Tipo</InputLabel>
            <Select
              value={otherPostgraduate.type}
              onChange={handleOtherPostgraduateInput}
              name="type"
              inputProps={{
                name: "type",
                id: "type-simple",
              }}
              error={!!formValidation.type}
              variant="standard"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="IDSS">IDSS</MenuItem>
              <MenuItem value="Sespas">Sespas</MenuItem>
              <MenuItem value="Patronato">Patronato</MenuItem>
            </Select>
            <FormHelperText>{formValidation.type}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <TextField
            required
            label="Hospital"
            margin="normal"
            fullWidth
            value={otherPostgraduate.hospital}
            name="hospital"
            onChange={handleOtherPostgraduateInput}
            error={!!formValidation.hospital}
            helperText={formValidation.hospital}
            variant="standard"
          />
        </div>
        <div className={styles.inputContainer}>
          <TextField
            required
            id="hospitalcity"
            label="Ciudad"
            margin="normal"
            fullWidth
            value={otherPostgraduate.city}
            name="city"
            onChange={handleOtherPostgraduateInput}
            className={clsx(styles.inputMedium, styles.inputRightSpace)}
            error={!!formValidation.city}
            helperText={formValidation.city}
            variant="standard"
          />
          <FormControl
            className={styles.inputSmall}
            fullWidth
            margin="normal"
            error={!!formValidation.phone}
          >
            <InputLabel htmlFor="hospitalphone-input">Teléfono</InputLabel>
            <Input
              value={otherPostgraduate.phone}
              onChange={handleOtherPostgraduateInput}
              name="phone"
              id="hospitalphone-input"
              inputComponent={TextMaskPhone}
              fullWidth
              required
              error={!!formValidation.phone}
              variant="standard"
            />
            <FormHelperText id="my-helper-text">
              {formValidation.phone}
            </FormHelperText>
          </FormControl>
        </div>
        <Button color="secondary" onClick={handleSelect}>
          Agregar
        </Button>
      </div>
    </div>
  );
}

export default OtherPostgraduate;
