import React, { useReducer, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Button
} from "@mui/material";
import { Link } from "react-router-dom";
import { getMember } from "../../../services/memberAPI";
import { saveInvoice as postInvoice } from "../../../services/invoiceAPI";
import { getServicesByStatus } from "../../../services/servicesAPI";
import FormSettings from "../../../components/paymentManagment/FormSettings/FormSettings";
import TitleBanner from "../../../components/paymentManagment/TitleBanner/TitleBanner";
import InvoiceOwner from "../components/InvoiceOwner/InvoiceOwner";
import ServicesForm from "../components/ServicesForm/ServicesForm";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper/SnackbarContentWrapper";
import ServicesDetails from "../../../components/paymentManagment/ServicesDetails/ServicesDetails";
import InvoicePrintPage from "../components/InvoicePrintPage/InvoicePrintPage";
import { reducer, initialState } from "./MemberReducer";
//import { makeStyles } from "@material-ui/core/styles";

const initialValidationError = {
  quantity: "",
  service: "",
  member: "",
  price: ""
};

function validationReducer(state, action) {
  switch (action.type) {
    case "change":
      return {
        ...state,
        [action.name]: action.value,
      };
    case "reset_disc_quantity":
      return {
        ...state,
        discount: "",
        quantity: ""
      }
    case "clean_validations":
      return {
        ...action.data
      }
    default:
      return state;
  }
}

/*
const useStyle = makeStyles(() => ({
    containerTopRoot: { marginTop: 15, marginBottom: 15 },
    bodyContainerRoot: { marginTop: '2rem', padding: 10 }
  }));
*/
let dateObj = new Date(Date.now());
let invoiceForPrint = {};

function Member() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [validState, dispatchValid] = useReducer(
    validationReducer,
    initialValidationError
  );
  const [isSaving, setIsSaving] = useState(false);

  function handleOnNewInvoice() {
    dispatch({
      type: "clean_form",
      date: dateObj,
    });
    dispatchValid({
      type: 'clean_validations',
      data: initialValidationError
    })
  }

  async function onSubmit() {
    try {
      setIsSaving(true);
      if (!state.invoice.services.length || !state.invoice.member.label) {
        dispatchValid({
          type: "change",
          name: "service",
          value: "Campos requeridos",
        });
        dispatchValid({
          type: "change",
          name: "member",
          value: "Campos requeridos",
        });
        setIsSaving(false);
        return;
      }
      let servicesFormat = [];
      let totalService = 0;
      let totalDiscount = 0;
      for (let x = 0; x < state.invoice.services.length; x++) {
        let service = state.invoice.services[x];
        servicesFormat.push({
          description: service.label,
          isMembership: service.body.isMembership,
          price: service.price,
          quantity: service.quantity,
          discount: service.discount,
          accountNumber: service.body.accountNumber,
          currency: service.body.currency
        });
        totalService += service.quantity * service.price;
        totalDiscount += service.discount;
      }

      let invoice = {
        ...state.invoice,
        services: servicesFormat,
        total: totalService - totalDiscount,
        totalDiscount: totalDiscount,
        paymentType: state.form.paymentType,
        member: {
          identifier: state.invoice.member.body.identifier,
          fullName: state.invoice.member.label,
          _id: state.invoice.member.body._id,
        },
        status: "paid",
        notes: state.form.notes
      };
      let { body: payload } = await postInvoice(invoice);
      setIsSaving(false);
      if (payload.success) {
        dispatch({
          type: "save_invoice"
        });
        dispatch({
          type: "change_prop_invoice",
          name: "id",
          value: payload.data.id,
        });
        
        printInvoice(payload.data.id);
        setIsSaving(false);
      } else {
        //setSuccess(false);
        // setMsg("serverError");
      }
    } catch (error) {
      setIsSaving(false);
    }
  }

  function printInvoice(id) {
    if (state.invoice.services.length && !!state.invoice.member.label) {
      let servicesFormat = [];
      state.invoice.services.map((service) => {
        servicesFormat.push({
          description: service.label,
          isMembership: service.body.isMembership,
          price: service.price,
          quantity: service.quantity,
          discount: service.discount
        });
      });

      invoiceForPrint = {
        ...state.invoice,
        client: {
          name: state.invoice.member.label,
          identifier: state.invoice.member.body.identifier
        },
        services: servicesFormat,
        notes: state.form.notes,
        paymentType: state.form.paymentType,
        id: id ? id : state.invoice.id ? state.invoice.id : "",
        voucherNumber: state.form.voucherNumber
      };

      dispatch({
        type: "change_print",
        name: "isPrint",
        value: true,
      });
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }

  function onChangeInput({ target: { value, name } }) {
    dispatch({ type: "change_prop_form", name, value });
    if (name === 'discount' || name === 'quantity') {
      dispatchValid({
        type: 'reset_disc_quantity'
      })
    }
  }

  async function handleSearchMembers(inputValue, callback) {
    try {
      let { body: payload } = await getMember("all", 0, 50, inputValue);
      if (payload.data && payload.data.length) {
        let members = payload.data.map((member) => ({
          value: member.id,
          label: member.familyName + " " + member.names,
          body: member,
        }));
        callback(members);
      } else {
        callback([]);
      }
    } catch (error) {
      console.error("ERROR ", error);
    }
  }

  function onCompostMember() {
    dispatch({
      type: "change_prop_form",
      name: "compost",
      value: true,
    });
    //setMsg("compost");
    let memberShipService = state.invoice.services.find(
      (service) => service.body.isSystem && service.body.isMembership
    );

    if (memberShipService && memberShipService.quantity < 3) {
      memberShipService.quantity = 3;
      dispatch({
        type: "modified_invoice_services",
        service: memberShipService,
      });
    } else {
      memberShipService = state.form.services.find(
        (service) => service.service.isSystem && service.service.isMembership
      );

      if (memberShipService) {
        memberShipService.quantity = 3;
        memberShipService.price = memberShipService.service.price;
        dispatch({
          type: "change_prop_invoice",
          name: "services",
          value: {
            value: memberShipService.value,
            label: memberShipService.label,
            quantity: memberShipService.quantity,
            price: memberShipService.price,
            body: memberShipService.service,
            discount: 0
          },
        });
      }
    }
  }

  function cleanCompostMember() {
    dispatch({
      type: "change_form_compost",
      name: "compost",
      value: false,
    });
  }

  function onDropDownMemberChange(newValue, { name }) {
    if (!!validState.member) {
      dispatchValid({ type: "change", name: "member", value: "" });
    }
    if (newValue.body.status === "inactive" && !state.form.compost) {
      onCompostMember();
    } else {
      cleanCompostMember();
    }

    dispatch({
      type: "change_prop_invoice",
      name: name,
      value: {
        value: newValue.value,
        label: newValue.label,
        body: {
          identifier: newValue.body.identifier,
          status: newValue.body.status,
          familyNames: newValue.body.familyNames,
          names: newValue.body.names,
          membershipToPay: newValue.body.membershipToPay,
          _id: newValue.body._id,
        },
      },
    });
  }

  function onAddService() {
    if (!Object.keys(state.form.service).length) {
      dispatchValid({
        type: "change",
        name: "service",
        value: "Seleccione un servicio",
      });
    } else if (!state.form.quantity) {
      dispatchValid({
        type: "change",
        name: "quantity",
        value: "El valor debe ser mayor que cero",
      });
    } else if (state.form.discount > 100) {//(state.form.quantity * state.form.service.service.price)) {
      dispatchValid({
        type: "change",
        name: "discount",
        value: `El valor debe ser menor que 100`,
      });
    } else {
      let service = state.invoice.services.find(
        (service) => service.value === state.form.service.value
      );
      if (service) {
        service.quantity = +service.quantity + +state.form.quantity;
        service.discount = state.form.discount > service.discount ? state.form.discount : service.discount;
        dispatch({ type: "modified_invoice_services", service });
      } else {
        dispatch({
          type: "change_prop_invoice",
          name: "services",
          value: {
            value: state.form.service.value,
            label: state.form.service.label,
            quantity: state.form.quantity,
            discount: state.form.discount || 0,
            price: state.form.service.service.price,
            body: state.form.service.service,
          },
        });
      }
      dispatch({ type: "reset_quantity" });
    }
  }

  function onDropDownServiceChange(newValue, { name }) {
    if (!!validState.service) {
      dispatchValid({ type: "change", name: "service", value: "" });
    }
    dispatch({
      type: "change_prop_form",
      name: "price",
      value: newValue.service.price,
    });
    dispatch({
      type: "change_prop_form",
      name: name,
      value: {
        value: newValue.value,
        label: newValue.label,
        service: newValue.service,
      },
    });
    
    dispatch({ type: "change_prop_invoice", name: 'currencyType', value: newValue.service.currency || 'DOP' });
    if (newValue.service.currency === 'USD') {
      dispatch({ type: "change_prop_invoice", name: 'currencyRate', value: 1 });
    }
  }

  function removeServices(id) {
    dispatch({ type: "remove_invoice_services", value: id });
  }

  function decreaseServiceQuantity(id) {
    let service = state.invoice.services.find(
      (service) => service.value === id
    );
    if (service.quantity === 1) {
      removeServices(id);
    } else {
      service.quantity = +service.quantity - 1;
      dispatch({ type: "modified_invoice_services", service });
    }
  }

  function onApplyITEBIS(event) {
    const { name, checked } = event.target;
    dispatch({ type: "change_prop_invoice", name, value: checked });
  }

  function onCurrencyChange(event) {
    const { name, value } = event.target;
    dispatch({ type: "change_prop_invoice", name, value });
  }

  function onChangeCurrencyRate(event) {
    const { name, value } = event.target;
    dispatch({ type: "change_prop_invoice", name, value });
  }

  useEffect(() => {
    (async () => {
      let { body: servicesFetched } = await getServicesByStatus("active", "", undefined);
      if (servicesFetched.success) {
        let services = [];
        servicesFetched.data.forEach((service) => {
          services[services.length] = {
            value: service.id,
            label: service.description,
            service,
          };
        });
        dispatch({ type: "fetched_services", payload: services });
      }
    })();
  }, []);


  return (
    <>
      <Box displayPrint="none">
        {/*<Container //classes={{root: classes.containerTopRoot}}
        >
          <Link to="/facturas">
            <Button
              color="primary"
            >
              Ir a Facturas
            </Button>
          </Link>
        </Container>
        */}
        <Container //classes={{root: classes.bodyContainerRoot}}
        >
          {!!state.httpMsg.msg && (
            <SnackbarContentWrapper
              variant={state.httpMsg.variant}
              message={state.httpMsg.msg}
            />
          )}
          
          <FormSettings
            newInvoice={handleOnNewInvoice}
            saveInvoice={onSubmit}
            status={state.status}
            formId={state.form._id}
            isSaving={state.isSaving}
            onChangeInput={onChangeInput}
            printInvoice={printInvoice}
            canPrint={state.status === "save"}
            isDisabled={
              state.status === ""
              || state.status === "save"
            }
            title="Recibo para Miembro"
          />
          
          <TitleBanner
            onChangeInput={onChangeInput}
            voucherNumber={state.form.voucherNumber}
            onApplyITEBIS={onApplyITEBIS}
            applyItebis={state.invoice.applyItebis}
            isDisabled={
              state.status === ""
              || state.status === "save"
            }
            currencyType={state.invoice.currencyType}
            currencyRate={state.invoice.currencyType === 'USD' ? state.invoice.currencyRate : 1}
            onCurrencyChange={onCurrencyChange}
            onChangeCurrencyRate={onChangeCurrencyRate}
            paymentType={state.form.paymentType}
          />
          <InvoiceOwner
            ownerType="Miembro"
            name="member"
            fetchItems={handleSearchMembers}
            onDropDownChange={onDropDownMemberChange}
            value={state.invoice.member}
            isDisabled={state.status === "" || state.status === "save" || state.isFetchMembers}
          />
          <ServicesForm
            form={state.form}
            onChangeInput={onChangeInput}
            onDropDownChange={onDropDownServiceChange}
            isDisabled={
              state.status === ""
              || state.status === "save"
            }
            isAddServiceDisabled={state.form.service && state.form.service.value === ""}
            validState={validState}
            onAddService={onAddService}
            currencyRate={state.invoice.currencyType === 'USD' ? state.invoice.currencyRate : 1}
          />
          <ServicesDetails
            services={state.invoice.services}
            removeServices={removeServices}
            compost={state.form.compost}
            decreaseServiceQuantity={decreaseServiceQuantity}
            isDisabled={
              state.status === ""
              || state.status === "save"
            }
            applyItebis={state.invoice.applyItebis}
            currencyRate={state.invoice.currencyType === 'USD' ? state.invoice.currencyRate : 1}
          />
        </Container>
      </Box>
      {state.isPrint ? (
        <InvoicePrintPage {...invoiceForPrint} isPrint={state.isPrint} isMember />
      ) : null}
    </>
  )
}

export default Member