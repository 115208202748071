import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { isEmpty } from "lodash";
import { Container } from "@mui/material";
import TableServices from "./TableServices";
import { AlertDialog, SearchBox, StatusTabs } from "../../components";
import { getServicesByStatus, updateService } from "../../services/servicesAPI";
import { SnackbarContentWrapper } from "../../components";
import { TopRow } from "../../components/servicies/TopRow/TopRow";
import reducer from "./reducer";
import styles from "./services.module.scss";
/*
const listMetaData = {
  loading: false,
  openDialog: false,
  filteredList: [],
  errorHttp: "",
  name: ""
};
*/

let initialState = {
  services: new Map(),
  searchText: "",
  isLoading: false,
  isUpdating: false,
  errorHttp: "",
  msg: "",
  variant: "",
};

let filteredList = [];
function List() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stageService, setStageService] = useState({});
  const [statusFilter, setStatusFilter] = useState("active");

  function getByName(text) {
    dispatch({ type: "SEARCH_TEXT", payload: text });
  }

  async function disableServices() {
    try {
      dispatch({ type: "UPDATE_SERVICE_REQUEST" });
      let service = {
        ...stageService,
        status: stageService.status === "active" ? "inactive" : "active",
      };
      let { body: payload } = await updateService(service);
      if (payload.success) {
        let newServices = new Map(state.services);
        newServices.set(service._id, service);
        dispatch({ type: "UPDATE_SERVICE_SUCCESS", payload: newServices });
        setStageService({});
      }
    } catch (error) {
      dispatch({ type: "UPDATE_SERVICE_FAILURE" });
    }
  }

  function cancelServiceDisable() {
    setStageService({});
  }

  const fillTable = useCallback((status) => {
    async function fetch(status) {
      dispatch({ type: "FETCH_SERVICES_REQUEST" });

      try {
        let { body: servicesFetched } = await getServicesByStatus(status);

        //let newServices = new Map();

        if (servicesFetched.success) {
          dispatch({
            type: "FETCH_SERVICES_SUCCESS",
            payload: servicesFetched.data,
          });
        }
      } catch (error) {
        dispatch({ type: "FETCH_SERVICES_FAILURE" });
      }
    }
    fetch(status);
  }, []);

  function handlerFilterByStatus(status) {
    setStatusFilter(status);
    fillTable(status);
  }

  useEffect(() => {
    fillTable();
  }, [fillTable]);

  useMemo(() => {
    if (!!state.searchText) {
      filteredList = Array.from(state.services.values()).filter((service) => {
        let serviceFound = null;
        let word = new RegExp(`^${state.searchText.toLocaleLowerCase()}`, "gi");
        if (word.test(service.description.toLocaleLowerCase())) {
          serviceFound = service;
        }
        return serviceFound;
      });
    } else {
      filteredList = Array.from(state.services.values()).filter(
        (service) => service.status === statusFilter
      );
    }
  }, [state.services, statusFilter, state.searchText]);

  return (
    <Container>
      <TopRow />
      {!isEmpty(stageService) && (
        <AlertDialog
          isOpen={!isEmpty(stageService)}
          title={
            stageService.status === "active"
              ? "Desabilitar él servicio"
              : "Habilitar él servicio"
          }
          message={`Desea ${
            stageService.status === "active" ? "desactivar" : "activar"
          }: ${stageService.description}`}
          onCancel={cancelServiceDisable}
          onOk={disableServices}
        />
      )}
      {!!state.msg && (
        <SnackbarContentWrapper variant={state.variant} message={state.msg} />
      )}
      <SearchBox getByName={getByName} extraFilters={false} placeholder="Descripción" />
      <div className={styles.statusContainer}>
        <StatusTabs
          onChangeStatus={handlerFilterByStatus}
          status={statusFilter}
        />
      </div>
      <TableServices
        loading={state.loading}
        servicesArray={filteredList}
        stageServicesForDisable={setStageService}
      />
    </Container>
  );
}

export default List;
