import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ButtonGroup,
  ClickAwayListener,
  Button,
  CircularProgress,
  Popper,
  MenuList,
  MenuItem,
  Grow,
  Paper,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ButtonGroupVertical = ({
  options,
  onHandleClick,
  isLoading,
  isDisabled,
  variant,
  color,
  className
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClick = () => {
    onHandleClick(selectedIndex);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant={variant}
        ref={anchorRef}
        aria-label="split button"
        disabled={isDisabled || isLoading}
        color={color}
        className={className}
      >
        <Button onClick={handleClick}>
          {isLoading ? <CircularProgress size={24}/> : null}
          {options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

ButtonGroupVertical.defaultProps = {
  variant: "contained",
  color: "primary"
};

ButtonGroupVertical.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onHandleClick: PropTypes.func,
  variant: PropTypes.oneOf(["outlined", "contained"]),
  color: PropTypes.oneOf(["primary"]),
};

export default ButtonGroupVertical;
