import React from "react";
import PropTypes from "prop-types";
import { Button, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/LocalPrintshop";
import { ButtonGroupVertical } from "../../";
import clsx from "clsx";
import styles from "./fromSettings.module.scss";
let dateObj = new Date();

const creditInvoice = "Guardar a crédito";
const options = ["Guardar", creditInvoice];
function FormSettingsStateBar({
  newInvoice,
  saveInvoice,
  formId,
  status,
  isDisabled,
  canPrint = true,
  isSaving,
  printInvoice,
  success,
  title,
}) {

  const onHandleSave = (option) => {
    let invoiceType = 'full';
    
    if (options[option] === creditInvoice) {
      invoiceType = 'credit'
    }
    saveInvoice(invoiceType)
  }

  return (
    <div className={styles.rowContainer}>
      <h3>{title}</h3>
      <div className={styles.container}>
        <div className={styles.settingsBtnContainer}>
          <Button
            onClick={newInvoice}
            variant="outlined"
            color="primary"
            disabled={isSaving}
            size="small"
          >
            Nuevo Factura
          </Button>

          <ButtonGroupVertical
            isLoading={isSaving}
            isDisabled={isDisabled}
            options={options}
            color="primary"
            onHandleClick={onHandleSave}
            className={styles.btnSave}
            size="small"
          />

        </div>
        <div className={clsx(styles.settingsBtnContainerSecond)}>
          <p className={clsx({ [styles.stateIndicator]: status !== "" })}>
            {status === "" ? "" : !!formId ? "Creado" : "Nuevo"}
          </p>
          <p className={styles.dateText}>
            {`${dateObj.getUTCDate()}/${
              dateObj.getUTCMonth() + 1
            }/${dateObj.getUTCFullYear()}`}
          </p>

          <Fab
            size="small"
            aria-label="imprimir"
            color={canPrint ? "primary" : "disabled"}
            onClick={() => printInvoice(null)}
            className={styles.printSpace}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>
    </div>
  );
}

FormSettingsStateBar.propTypes = {
  newInvoice: PropTypes.func,
  saveInvoice: PropTypes.func,
  classes: PropTypes.object,
  invoice: PropTypes.object,
};
export default FormSettingsStateBar;
