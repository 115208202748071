import React, { useEffect, useState } from 'react';
import { findAllcatalogoCuenta } from '../../../services/catalogoCuentaAPI';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
} from "@mui/material";

const CatalogoCuentaList = () => {
    const [catalogoCuenta, setCatalogoCuenta] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
          try {
            const payload = await findAllcatalogoCuenta();
            console.log('payload***', payload);
            if (payload.body.success) {
                setLoading(false);
                setCatalogoCuenta(payload.body.data);
            }
          } catch (e) {}
        })()
    },[])

    return (
        <>
        <h2>Catalogo de cuentas</h2>
        {loading ? (
        <>
          <CircularProgress />
          <p>Cargando...</p>
        </>
      ) : (
        <Table>
          <TableHead>
              <TableRow>
                <TableCell align="left">No.</TableCell>
                <TableCell align="left">Codigo</TableCell>
                <TableCell align="left">Descripcion</TableCell>
                <TableCell align="left">Cuenta</TableCell>
                <TableCell align="left">Naturaleza</TableCell>
                <TableCell align="left">Divisa</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
            {
                catalogoCuenta.map((cuenta, index) => (
                    <TableRow
                    key={`${index}-${cuenta.code}`}
                    className={
                        cuenta.activa
                        ? "row_active"                        
                        : "row_disable"
                    }
                  >
                    <TableCell>{index}</TableCell>
                    <TableCell>{cuenta.code}</TableCell>
                    <TableCell>{cuenta.descripcion}</TableCell>
                    <TableCell>{cuenta.numero}</TableCell>
                    <TableCell>{cuenta.naturaleza}</TableCell>
                    <TableCell>{cuenta.divisa}</TableCell>
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
      )}
        </>
    )
}

export default CatalogoCuentaList;
