import React from "react";
import { func, string } from "prop-types";
import { Button, Grid } from "@mui/material";

// makeStyles DEPRECATED
/*
const useStyles = makeStyles(theme => createStyles({
  root: {
    padding: theme.spacing(3)
  }
}));
*/
function StatusTabs({ status, onChangeStatus }) {
  return (
    <Grid justify="flex-end" alignItems="flex-end" container spacing={2}>
      <Grid item>
        <Button
          onClick={() => onChangeStatus("active")}
          variant="outlined"
          color={status === "active" ? "primary" : "secondary"}
        >
          ACTIVOS
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => onChangeStatus("inactive")}
          variant="outlined"
          color={status === "active" ? "secondary" : "primary"}
        >
          INACTIVOS
        </Button>
      </Grid>
    </Grid>
  );
}

StatusTabs.propTypes = {
  onChangeStatus: func,
  status: string.isRequired,
};

export default StatusTabs;
