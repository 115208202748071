import React, { useEffect, useState } from 'react';
import API from "../../config/api";
import { ButtonLink } from '../../components';
import TableDeposit from '../../components/Deposit/List';
import { useToastSetter } from "../../context/ToastContext";

const DepositList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [deposits, setDeposits] = useState([]);

    const setToast = useToastSetter();
    useEffect(() => {
        setIsLoading(true);
        (async () => {
            try {            
            const payload = await API.get('check_transfer/all');
            setDeposits(payload);
           } catch(error) {
            setToast({
                open: true,
                variant: "error",
                message: error.message,
              });
           } finally {
            setIsLoading(false);
           }
        })()
    },[])

    return (
    <div>
        <ButtonLink to='/deposito/nuevo'>Nuevo Deposito</ButtonLink>
        
        <TableDeposit loading={isLoading} deposits={deposits}/>
        </div>)
}

export default DepositList;
