import React from "react";
import { bool, array } from "prop-types";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Icon,
} from "@mui/material";

function  TableProviders({loading, providers}) {
    return (
        <>
          {loading ? (
            <>
              <CircularProgress />
              <p>Cargando...</p>
            </>             
          ) :(<Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">No.</TableCell>
                    <TableCell align="left">Nombre</TableCell>
                    <TableCell align="left">RNC</TableCell>
                    <TableCell align="left">Phone</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Opción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {
                        providers ? providers.map((row, idx) => (
                            <TableRow key={`company-${idx}`}>
                              <TableCell align="left">{idx + 1}</TableCell>
                              <TableCell>{row.fullName}</TableCell>
                              <TableCell align="left">{row.registerNumber}</TableCell>
                              <TableCell align="left">{row.phoneNumber}</TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">{row.isActive ? "Activo" : "Inactivo"}</TableCell>
                              <TableCell align="left">
                                <Link to={`/proveedor/${row._id}`}>
                                  <Icon>edit</Icon>
                                </Link>
                              </TableCell>
                            </TableRow>
                        )) : <p>No existe ningun registro.</p>
                    }
                </TableBody>
              </Table>)}
        </>
    )
}

TableProviders.propTypes = {
    loading: bool,
    providers: array
}

export default TableProviders;

