import React from 'react'
import { Link } from 'react-router-dom';
import {
    Button
} from '@mui/material';
import styles from "./buttonLink.module.scss";

function ButtonLink({children, ...props}) {
    return (
          <Button component={Link} {...props}>
              {children}
          </Button>
    )
}

export default ButtonLink