import React, { useEffect, useState } from "react";
import {
  Radio,
  RadioGroup,
  Button,
  FormControlLabel,
  TextField,
} from "@mui/material";
import Select from "react-select";
import DialogForm from "../../components/Dialog/DialogForm";
import API from "../../config/api";
import { useToastSetter } from "../../context/ToastContext";
import { onlyNumber } from "../../utils/validators";
import styles from "./deposit.module.scss";

const dateObj = new Date();
const initialValue = {
  sequence: "",
  amount: 0,
  supplierId: "",
  notes: "",
};

const Form = () => {
  const [type, setType] = useState("");
  const [form, setForm] = useState(initialValue);
  const [suppliers, setSuppliers] = useState([]);
  const [formState, setFormState] = useState("");
  const [ isLoading, setIsLoading] = useState(false);

  const setToast = useToastSetter();

  const newDeposit = () => {
    setFormState("open");
  };

  const handleOnChangeDepositType = (e) => {
    const { value } = e.target;
    setType(value);
  };

  const onCloseDepositType = () => {
    setFormState("");
  };

  const onAcceptDepositType = () => {
    setFormState("new");
  };

  const onChangeSupplier = (newValue, { name }) => {
    const { value, label, ...rest } = newValue;

    setForm((prev) => ({
      ...prev,
      supplierId: value,
    }));
  };

  const onHandleForm = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
    }
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = await API.post(`check_transfer`, {...form, type});
      console.log("Payload***", payload);
      setToast({
        open: true,
        variant: "success",
        message: "El deposito se guardo con exito!",
      });
    } catch(error) {
      setToast({
        open: true,
        variant: "error",
        message: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (type) {
        try {
          const payload = await API.get(`check_transfer/next_sequence/${type}`);
          setForm((prev) => ({ ...prev, sequence: payload }));
        } catch (error) {
          setToast({
            open: true,
            variant: "error",
            message: error.message,
          });
        }
      }
    })();
  }, [type]);

  useEffect(() => {
    (async () => {
      try {
        const payload = await API.get("commercial_supplier/all/true");
        console.log("SUPPLIDOR***", payload);
        setSuppliers(
          payload.map((supplier) => ({
            value: supplier._id,
            label: supplier.fullName,
            rnc: supplier.registerNumber,
          }))
        );
      } catch (error) {
        setToast({
          open: true,
          variant: "error",
          message: error.message,
        });
      }
    })();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>
          Deposito de {type === "check" ? "Cheque" : "Transferencia"}
        </h3>

        <p className={styles.dateText}>
          {`${dateObj.getUTCDate()}/${
            dateObj.getUTCMonth() + 1
          }/${dateObj.getUTCFullYear()}`}
        </p>
      </div>
      <div className={styles.newContainer}>
        {form.sequence ?<div>
        <span className={styles.numberLabel}>No.</span> 
        <span className={styles.counter}>{form.sequence}</span></div> : null}
        
        <Button variant="outlined" onClick={() => newDeposit("open")}>Nuevo deposito</Button>
      </div>
      <DialogForm
        open={formState === "open"}
        title="Tipo de deposito"
        onClose={onCloseDepositType}
        onAccept={onAcceptDepositType}
      >
        <div>
          <RadioGroup
            aria-labelledby="type_of_deposit"
            name="depositType"
            onChange={handleOnChangeDepositType}
          >
            <FormControlLabel
              value="check"
              control={<Radio />}
              label="Cheque"
            />
            <FormControlLabel
              value="tr"
              control={<Radio />}
              label="Transferencia"
            />
          </RadioGroup>
        </div>
      </DialogForm>

      <div className={styles.supplierContainer}>
        <div className={styles.supplier}>
          <label>Supplidor</label>
          <Select
            name="supplier"
            /*className={`${styles.servicesDropdown} ${styles.service_drop_down} ${
            !!validState.service ? styles.service_drop_down_error : ""
          }`}
          */
            classNamePrefix="supplier"
            options={suppliers}
            onChange={onChangeSupplier}
            isDisabled={formState !== "new"}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          />
        </div>
        <TextField
          label="Monton"
          onChange={onHandleForm}
          name="amount"
          type="number"
          value={form.amount}
          disabled={formState !== "new"}
        />
      </div>
      <div className={styles.secondContainer}>
        <TextField
          label="Concepto"
          name="notes"
          value={form.notes}
          multiline
          rows={4}
          maxRows={4}
          className={styles.notes}
          onChange={onHandleForm}
        />
        <Button disabled={formState !== 'new' || isLoading} onClick={onSubmit} variant="contained">
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default Form;
