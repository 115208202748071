import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress, TextField } from "@mui/material";
import floor from "lodash.floor";
import PaymentType from "../../../components/paymentManagment/PaymentType/PaymentType";
import API from "../../../config/api";
import { useNavigate, useParams } from "react-router-dom";
import { useToastSetter } from "../../../context/ToastContext";
import { useQuery } from "../../../hooks/useQuery";
import { formatCurrency, validateForm } from "../../../utils/utils";
import InvoiceCreditPrint from "../InvoiceCreditPrint/InvoiceCreditPrint";

import styles from "./form.module.scss";

let dateObj = new Date();
let invoiceForPrint = {};

const initialForm = {
  amount: 0,
  notes: "",
  paymentType: "creditCard",
  taxpayerRegistryNumber: "",
  date: new Date(Date.now())
};

const InvoiceCreditPay = () => {
  const [form, setForm] = useState(initialForm);
  const [invoice, setInvoice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const { invoiceId } = useParams();
  const setToast = useToastSetter();

  const handleChange = (e) => {
    const { name, value } = e.target;

    /*
    if (name === "amount" &&  !/[0-9]/.test(value)) {
      return;
    }
    */
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSaving(true);
      const valid = validateForm(form, ["amount", "notes"]);
      if (valid.isValid) {
        const data = {
          ...form,
          invoiceId,
          ownerId: invoice.owner._id,
          amountPandding:
            floor(invoice.total, 2) -
            (floor(invoice.totalQuota, 2) + floor(form.amount, 2)),
        };
        const payload = await API.post("quota_invoice/save", data);
       
        setForm(prev => ({
          ...prev,
          id: payload.id
        }))

        onPrint();
      }
      /*
      if (!/[0-9]/.test(form.amount)) {
        setToast({
          open: true,
          variant: 'info',
          message: 'El monto solo debe ser numerico'
        });
      } else if (form.notes) {
        setToast({
          open: true,
          variant: 'info',
          message: 'La nota es obligatoria'
        });
      }
      */
    } catch (error) {
      setToast({
        open: true,
        variant: "error",
        message: error.messege,
      });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const payload = await API.get(`invoice/${invoiceId}`);
        setInvoice(payload);
      } catch (error) {
        setToast({
          open: true,
          variant: "error",
          message: "HERE IS ",
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);


  const onPrint = () => {
    setIsPrint(true);
    setTimeout(() => {
      window.print();
    }, 1000);
  }

  return (
    <div>
      <div className={styles.container}>
        <h3 className={styles.title}>Factura parcial</h3>
        <p className={styles.dateText}>
          {`${dateObj.getUTCDate()}/${
            dateObj.getUTCMonth() + 1
          }/${dateObj.getUTCFullYear()}`}
        </p>
      </div>
      {isLoading || !invoice ? (
        <CircularProgress />
      ) : (
        <div className={styles.container}>
          <div>
            <div>
              <p className={styles.invoiceNum}>
                <span className={styles.bolder}>No. Fact</span>
                {invoice.id}
              </p>
              <TextField
                name="taxpayerRegistryNumber"
                label="RNC"
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                value={form.taxpayerRegistryNumber}
                size="small"
              />
            </div>
            <PaymentType
              onChangeInput={handleChange}
              paymentType={form.paymentType}
              isDisabled={false}
            />
          </div>
          <div className={styles.ownerContainer}>
            <TextField
              label="Titular"
              variant="standard"
              value={invoice.owner.name}
              readonly
              className={styles.owner}
            />
            <TextField
              label="Monto"
              name="amount"
              value={form.amount}
              onChange={handleChange}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </div>
          <div className={styles.noteContainer}>
            <TextField
              label="Notas"
              className={styles.notes}
              multiline
              rows={5}
              value={form.notes}
              name="notes"
              variant="outlined"
              onChange={handleChange}
            />
            <div className={styles.totalDetail}>
              <div className={styles.leyend}>
                <label className={styles.leyendLabel}>Pagado:</label>{" "}
                <p className={styles.leyendValue}>
                  {formatCurrency(invoice.totalQuota)}
                </p>
              </div>
              <div className={styles.leyend}>
                <label className={styles.leyendLabel}>Total:</label>{" "}
                <p className={styles.leyendValue}>
                  {formatCurrency(invoice.total)}
                </p>
              </div>
            </div>
          </div>
          <Button
            onClick={handleSubmit}
            className={styles.submit}
            variant="contained"
          >
            {isSaving ? <CircularProgress /> : null}Guardar
          </Button>
        </div>
      )}
      { isPrint ? 
      <InvoiceCreditPrint 
        isHalfPaper={true} 
        {...form}
        invoiceId={invoiceId}
        owner={invoice.owner.name}
      /> : null }
    </div>
  );
};

InvoiceCreditPay.propTypes = {
  invoiceId: PropTypes.string,
};
export default InvoiceCreditPay;
