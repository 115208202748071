import React, { useState } from "react";
import clsx from "classnames";
import { documentsMembers } from "../../../services/memberAPI";
import { SearchBox } from "../../../components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Container,
  Box,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import style from '../../../styles/general.module.scss';

/*
const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  counterCountainer: {
    display: 'flex',

  },
  square: {
    minWidth: 24,
    height: 24,
    backgroundColor: '#3f51b5',
    marginRight: '.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  completed: {
    color: 'white',
    zIndex: 1,
    fontSize: 12,
    textAlign: 'center'
  },
  notApplied: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: 'white',
    zIndex: 1,
    fontSize: 12,
    textAlign: 'center'
  }
});
*/
/*
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: '13px',
    fontSize: '0.758rem'
  }
}))(TableCell);
*/
function QontoStepIcon(props) {
  //const classes = useQontoStepIconStyles();
  const { count } = props;
  return (
    <div
    /*className={clsx(classes.root, {
        [classes.active]: true,
      })}*/
    >
      <div //className={classes.counterCountainer}
      >
        <div //className={`${classes.square} ${classes.completed}`}
        >
          <p>{count ? count.applied : 0}</p>
        </div>
        <div //className={`${classes.square} ${classes.notApplied}`}
        >
          <p>{count ? count.notApplied : 0}</p>
        </div>
      </div>
    </div>
  );
}

function DocumentsList() {
  const [documents, setDocuments] = useState({
    list: [],
    counts: {},
  });
  const [searchLoading, setSearchLoading] = useState(false);

  async function getByName(textToSearch) {
    try {
      setSearchLoading(true);
      if (!!textToSearch) {
        fetchDocuments(textToSearch);
      }
      setSearchLoading(false);
    } catch (error) {
      setSearchLoading(false);
    }
  }

  async function fetchDocuments(textToSearch) {
    try {
      let { body } = await documentsMembers(textToSearch);
      setDocuments({
        list: body.data.documents,
        counts: {
          ...body.data.counts,
        },
      });
      /*
        if (payload.success) {
            const data = Array.isArray(payload.data) ? payload.data : [payload.data]
            setMembers(data);
          } else {
            setHttpStatus({type: 'warning', msg: `No se encontro ningun miembro(s)... ${textToSearch}`})
          }
          */
    } catch (e) {}
  }

  React.useEffect(() => {
    fetchDocuments("");
  }, []);

  return (
    <Container className={style.container}>
      <SearchBox
        searchLoading={searchLoading}
        getByName={getByName}
        placeholder="Cédula, Nombres o Apellidos(mínimo 3 caracteres)"
      />
      <Stepper alternativeLabel>
        <Step>
          <StepLabel
            StepIconProps={{
              count: documents.counts.exequatur,
            }}
            StepIconComponent={QontoStepIcon}
            active={false}
          >
            Exequatur
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconProps={{
              count: documents.counts.doctorTitle,
            }}
            StepIconComponent={QontoStepIcon}
          >
            T. Doc General
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconProps={{
              count: documents.counts.degreeAnesthesiology,
            }}
            StepIconComponent={QontoStepIcon}
          >
            T. Anestesiología
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconProps={{
              count: documents.counts.cv,
            }}
            StepIconComponent={QontoStepIcon}
          >
            CV
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconProps={{
              count: documents.counts.collegeCard,
            }}
            StepIconComponent={QontoStepIcon}
          >
            CMD
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconProps={{
              count: documents.counts.letterRecommendation,
            }}
            StepIconComponent={QontoStepIcon}
          >
            Carta recomendación
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconProps={{
              count: documents.counts.presentScientificTopic,
            }}
            StepIconComponent={QontoStepIcon}
          >
            Tema Cientifico
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconProps={{
              count: documents.counts.inscription,
            }}
            StepIconComponent={QontoStepIcon}
          >
            Inscription
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconProps={{
              count: documents.counts.two2x2photos,
            }}
            StepIconComponent={QontoStepIcon}
          >
            fotos
          </StepLabel>
        </Step>
      </Stepper>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Apellido</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Cédula</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.list.map((item, idx) => (
              <>
                <TableRow key={`${item.identifier}-${idx}`}>
                  <TableCell>{item.familyName}</TableCell>
                  <TableCell>{item.names}</TableCell>
                  <TableCell>{item.identifier}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={5}
                  >
                    <Box margin={1}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>Exequatur</TableCell>
                            <TableCell>T. Doc. General</TableCell>
                            <TableCell>T. Anestesiología</TableCell>
                            <TableCell>CV</TableCell>
                            <TableCell>CMD y Certificación</TableCell>
                            <TableCell>Carta de recomendación</TableCell>
                            <TableCell>Tema Cientifico</TableCell>
                            <TableCell>Inscripción</TableCell>
                            <TableCell>fotos</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              {item.exequatur ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {item.generalDoctorTitle ? (
                                <CheckCircleOutlineIcon fontSize="small" />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {item.degreeInAnesthesiology ? (
                                <CheckCircleOutlineIcon fontSize="small" />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {item.curriculumVitae ? (
                                <CheckCircleOutlineIcon fontSize="small" />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {item.medicalCollegeCardAndCertification ? (
                                <CheckCircleOutlineIcon fontSize="small" />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {item.letterOfRecommendationAnesthesiologists ? (
                                <CheckCircleOutlineIcon fontSize="small" />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {item.presentScientificTopic ? (
                                <CheckCircleOutlineIcon fontSize="small" />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {item.inscription ? (
                                <CheckCircleOutlineIcon fontSize="small" />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {item.two2x2photos ? (
                                <CheckCircleOutlineIcon fontSize="small" />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default DocumentsList;
