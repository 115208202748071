import React, { useEffect, useState } from "react";
import API from "../../../config/api";
import { TablePartialInvoice } from "../../../components/InvoiceCredit/TablePartialInvoice/TablePartialInvoice";

const ListPartialInvoice = () => {
  const [partial, setPartial] = useState([]);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const payload = await API.get("quota_invoice");
        setPartial(payload);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <TablePartialInvoice
      loading={loading}
      loadPrintAndShow={() => {}}
      partialInvoice={partial}
    />
  );
};

export default ListPartialInvoice;
