import React from 'react';
import {Grid} from '@mui/material';


function MemberItemTemplate ({
    value,
    label,
    body
}) {
    return (
        <Grid container>
          <Grid item xs={6}>{label}</Grid>
          <Grid item xs={2}>{body && (body.status === 'active' ? 'activo' : 'inactivo')}</Grid>
          <Grid item xs={1}>{body && (body.membershipToPay || 0)}</Grid>
        </Grid>
    )
}

export default MemberItemTemplate