module.exports = {
    Region: [
        {
          label: 'Sur',
          value: 'Sur'
        },
        {
            label: 'Cibao',
            value: 'Cibao'
        },
        {
            label: 'Este',
            value: 'Este'
        }
    ],
    Providence: [
        {
            label: 'Azua',
            value: 'Azua',
            parent: 'Sur'
        },
        {
            label: 'Neiba',
            value: 'Neiba',
            parent: 'Sur'
        },
        {
            label: 'Barahona',
            value: 'Barahona',
            parent: 'Sur'
        },
        {
            label: 'Dajabón',
            value: 'Dajabon',
            parent: 'Cibao'
        },
        {
            label: 'Santo Domingo',
            value: 'Santo Domingo',
            parent: 'Este'
        },
        {
            label: 'Duarte',
            value: 'Duarte',
            parent: 'Cibao'
        },
        {
            label: 'Comendador',
            value: 'Comendador',
            parent: 'Sur'
        },
        {
            label: 'El Seibo',
            value: 'El Seibo',
            parent: 'Este'
        },
        {
            label: 'Espaillat',
            value: 'Espaillat',
            parent: 'Cibao'
        },
        {
            label: 'Hato Mayor',
            value: 'Hato Mayor',
            parent: 'Este'
        },
        {
            label: 'Salcedo',
            value: 'Salcedo',
            parent: 'Cibao'
        },
        {
            label: 'Jimani',
            value: 'Jimani',
            parent: 'Sur'
        },
        {
            label: 'Duvergé',
            value: 'Duverge',
            parent: 'Sur'
        },
        {
            label: 'La Altagracia',
            value: 'La Altagracia',
            parent: 'Este'
        },
        {
            label: 'La Romana',
            value: 'La Romana',
            parent: 'Este'
        },
        {
            label: 'La Vega',
            value: 'La Vega',
            parent: 'Cibao'
        },
        {
            label: 'María Trinidad Sánchez',
            value: 'Maria Trinidad Sanchez',
            parent: 'Cibao'
        },
        {
            label: 'Monseñor Nouel',
            value: 'Monseñor Nouel',
            parent: 'Cibao'
        },
        {
            label: 'San Fernando de Monte Cristi',
            value: 'San Fernando de Monte Cristi',
            parent: 'Cibao'
        },
        {
            label: 'Monte Plata',
            value: 'Monte Plata',
            parent: 'Este'
        },
        {
            label: 'Bayaguana',
            value: 'Bayaguana',
            parent: 'Este'
        },
        {
            label: 'Pedernales',
            value: 'Pedernales',
            parent: 'Sur'
        },
        {
            label: 'Peravia',
            value: 'Peravia',
            parent: 'Sur'
        },
        {
            label: 'Puerto Plata',
            value: 'Puerto Plata',
            parent: 'Cibao'
        },
        {
            label: 'Samaná',
            value: 'Samana',
            parent: 'Cibao'
        },
        {
            label: 'San Cristóbal',
            value: 'San Cristobal',
            parent: 'Sur'
        },
        {
            label: 'San José de Ocoa',
            value: 'San Jose de Ocoa',
            parent: 'Sur'
        },
        {
            label: 'San Juan',
            value: 'San Juan',
            parent: 'Sur'
        },
        {
            label: 'San Pedro de Macorís',
            value: 'San Pedro de Macoris',
            parent: 'Este'
        },
        {
            label: 'Sánchez Ramirez',
            value: 'Sanchez Ramirez',
            parent: 'Cibao'
        },
        {
            label: 'Santiago',
            value: 'Santiago',
            parent: 'Cibao'
        },
        {
            label: 'San Ignacio de Sabaneta',
            value: 'San Ignacio de Sabaneta',
            parent: 'Cibao'
        },
        {
            label: 'Santa Cruz de Mao',
            value: 'Santa Cruz de Mao',
            parent: 'Cibao'
        },
        {
            label: 'Valverde',
            value: 'Valverde',
            parent: 'Cibao'
        },
        {
            label: 'Hermanas Mirabal',
            value: 'Hermanas Mirabal',
            parent: 'Cibao'
        },
        {
            label: 'Santiago Rodríguez',
            value: 'Santiago Rodriguez',
            parent: 'Cibao'
        },

    ],
    Municipio: [
        {
            label: 'Santo Domingo Este',
            value: 'Santo Domingo Este',
            parent: 'Santo Domingo'
        },
        {
            label: 'Distrito Nacional',
            value: 'Distrito Nacional',
            parent: 'Santo Domingo'
        },
        {
            label: 'Santo Domingo Norte',
            value: 'Santo Domingo Norte',
            parent: 'Santo Domingo'
        },
        {
            label: 'Santo Domingo Oeste',
            value: 'Santo Domingo Oeste',
            parent: 'Santo Domingo'
        },
        {
            label: 'Santiago de los Caballeros',
            value: 'Santiago de los Caballeros',
            parent: 'Santiago'
        },
        {
            label: 'Higuey',
            value: 'Higuey',
            parent: 'La Altagracia'
        },
        {
            label: 'San Cristóbal',
            value: 'San Cristobal',
            parent: 'San Cristobal'
        },
        {
            label: 'San Pedro de Macorís',
            value: 'San Pedro de Macoris',
            parent: 'San Pedro de Macoris'
        },
        {
            label: 'San Francisco de Macorís',
            value: 'San Francisco de Macoris',
            parent: 'Duarte'
        },
        {
            label: 'Boca chica',
            value: 'Boca chica',
            parent: 'Santo Domingo'
        },
        {
            label: 'San Felipe de Puerto Plata',
            value: 'San Felipe de Puerto Plata',
            parent: 'Puerto Plata'
        },
        {
            label: 'La Romana',
            value: 'La Romana',
            parent: 'La Romana'
        },
        {
            label: 'Santa Cruz de Barahona',
            value: 'Santa Cruz de Barahona',
            parent: 'Barahona'
        },
        {
            label: 'Baní',
            value: 'Bani',
            parent: 'Peravia'
        },
        {
            label: 'San Juan de la Maguana',
            value: 'San Juan de la Maguana',
            parent: 'San Juan'
        },
        {
            label: 'Bonao',
            value: 'Bonao',
            parent: 'Monseñor Nouel'
        },
        {
            label: 'Moca',
            value: 'Moca',
            parent: 'Espaillat'
        },
        {
            label: 'Gaspar Hernández',
            value: 'Gaspar Hernandez',
            parent: 'Espaillat'
        },
        {
            label: 'Jamao al Norte',
            value: 'Jamao al Norte',
            parent: 'Espaillat'
        },
        {
            label: 'Cayetano Germosén',
            value: 'Cayetano Germosen',
            parent: 'Espaillat'
        },
        {
            label: 'San Víctor',
            value: 'San Víctor',
            parent: 'Espaillat'
        },
        {
            label: 'Azua de Compostela',
            value: 'Azua de Compostela',
            parent: 'Azua'
        },
        {
            label: 'Cotuí',
            value: 'Cotui',
            parent: 'Sanchez Ramirez'
        },
        {
            label: 'Santa Cruz de El Seibo',
            value: 'Santa Cruz de El Seibo',
            parent: 'El Seibo'
        },
        {
            label: 'Dajabon',
            value: 'Dajabon',
            parent: 'Dajabon'
        },
        {
            label: 'El Pino',
            value: 'El Pino',
            parent: 'Dajabon'
        },
        {
            label: 'Loma de Cabrera',
            value: 'Loma de Cabrera',
            parent: 'Dajabon'
        },
        {
            label: 'Partido',
            value: 'Partido',
            parent: 'Dajabon'
        },
        {
            label: 'Restauración',
            value: 'Restauracion',
            parent: 'Dajabon'
        },
        {
            label: 'Jarabacoa',
            value: 'Jarabacoa',
            parent: 'La Vega'
        },
        {
            label: 'Constanza',
            value: 'Constanza',
            parent: 'La Vega'
        },
        {
            label: 'Concepción de la Vega',
            value: 'Concepcion de la Vega',
            parent: 'La Vega'
        },
        {
            label: 'Jima Abajo',
            value: 'Jima Abajo',
            parent: 'La Vega'
        },
        {
            label: 'Nagua',
            value: 'Nagua',
            parent: 'Maria Trinidad Sanchez'
        },
        {
            label: 'Cabrera',
            value: 'Cabrera',
            parent: 'Maria Trinidad Sanchez'
        },
        {
            label: 'El Factor',
            value: 'El Factor',
            parent: 'Maria Trinidad Sanchez'
        },
        {
            label: 'Río San Juan',
            value: 'Rio San Juan',
            parent: 'Maria Trinidad Sanchez'
        },
        {
            label: 'Santa Bárbara de Samaná',
            value: 'Santa Barbara de Samana',
            parent: 'Samana'
        },
        {
            label: 'Tamboril',
            value: 'Tamboril',
            parent: 'Santiago'
        },
        {
            label: 'Mao',
            value: 'Mao',
            parent: 'Valverde'
        },
        {
            label: 'Esperanza',
            value: 'Esperanza',
            parent: 'Valverde'
        },
        {
            label: 'Pedro Brand',
            value: 'Pedro Brand',
            parent: 'Santo Domingo'
        },
        {
            label: 'Sosúa',
            value: 'Sosua',
            parent: 'Puerto Plata'
        },
        {
            label: 'Hato Mayor del Rey',
            value: 'Hato Mayor del Rey',
            parent: 'Hato Mayor'
        },       
        {
            label: 'Villa Bisonó',
            value: 'Villa Bisono',
            parent: 'Santiago'
        },
        {
            label: 'Salcedo',
            value: 'Salcedo',
            parent: 'Hermanas Mirabal'
        },
        {
            label: 'Villa Altagracia',
            value: 'Villa Altagracia',
            parent: 'San Cristobal'
        },
        {
            label: 'Las Matas de Farfán',
            value: 'Las Matas de Farfan',
            parent: 'San Juan'
        },
        {
            label: 'Monte Plata',
            value: 'Monte Plata',
            parent: 'Monte Plata'
        },
        {
            label: 'Yamasá',
            value: 'Yamasa',
            parent: 'Monte Plata'
        },
        {
            label: 'San Ignacio de Sabaneta',
            value: 'San Ignacio de Sabaneta',
            parent: 'Santiago Rodriguez'
        },
        {
            label: 'San José de Las Matas',
            value: 'San Jose de Las Matas',
            parent: 'Santiago'
        },
        {
            label: 'San Antonio de Guerra',
            value: 'San Antonio de Guerra',
            parent: 'Santo Domingo'
        },
        {
            label: 'San José de Ocoa',
            value: 'San Jose de Ocoa',
            parent: 'San Jose de Ocoa'
        },
    ]
}