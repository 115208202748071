import API from "../config/api";

const BASE = "customer";

export function documentsMembers(text) {
  let query = "";
  if (!!text) {
    query += `?text=${text}`;
  }
  return API.get(`member/documentationsOfMembers${query}`)
}

export function getLastMember() {
  return API.get(`member/last-member-updated`);
}

export function postMember(member) {
  return API.post(`member/save`, member);
}

export function deleteMember(memberId) {
  return API.post('member/delete', memberId);
}

export function getMember(
  status = "active",
  page = 0,
  pageSize,
  text,
  identifier,
  isDeceased=false,
  memberType
) {

  let query = `?member_type=${memberType}`;

  if (status !== "all") {
    query += `&status=${status}`;
  }
  
  if (page != null) {
    query += `&page=${page}`;
  }

  query += `&isDeceased=${isDeceased}`;

  if (!!identifier) {
    query += `&identifier=${identifier}`;
  }
  if (!!text) {
    query += `&text=${text}`;
  }
  if (pageSize != null) {
    query += `&pageSize=${pageSize}`;
  }
  return API.get(`member/all${query}`);
}

export function getMemberByIdentifier(identifier) {
  return API.get(`member/identifier/${identifier}`);
}

export function getMemberBySchoolCode(schoolCode) {
  return API.get(`member/school-code/${schoolCode}`);
}

export function getCustomersMetadata(memberType) {
  return API.get(`member/metadata?member_type=${memberType}`);
}

export function putMember(member) {
  return API.put(`member/update`, { ...member });
}

export function getMembersListExcel() {
  return API.get('member/members-list-excel')
}

//FROM HERE ON WILL BE DELETE

export function getAllCustomersByLastName(familyName) {
  return API.get(`customers/all-with-family-name?familyName=${familyName}`);
}

export function getMemberByFilter(status, familyName, lastEvaluatedKey = null) {
  return API.get(
    `customers/filters?status=${status}&familyName=${familyName}&lastEvaluatedKey=${lastEvaluatedKey}`
  );
}

export function updateStatus(identifier, status) {
  return API.put(`${BASE}/state`, {
    identifier: identifier,
    status: status,
  });
}

//Work ith DynamoDB
/*
export function getMemberByIdentifier(identifier) {
  return API.get(`${BASE}?memberId=${identifier}`);
}

export function getCustomersMetadata() {
  return API.get(`${BASE}/customers/metadata`);
}

export function putMember(identifier, member) {
  return API.put(`${BASE}`, {
    identifier: identifier,
    data: {
      ...member
    }
  });
}
*/