import * as React from "react";
import Menu from "../../components/Menu";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";

function PrivateRouter({ component: Component, ...rest }) {
  const [storage] = useLocalStorage("secret");
  const location = useLocation();
  /*
  if (!storage || storage === undefined) {
    return <Navigate to={{
      pathname: "/login", state: {from: location }}} replace />;
  }
  */
  return <Outlet />;
  return (
    <>
      <Menu {...rest} />
      <Component {...rest} />
    </>
  );
}

export default PrivateRouter;
