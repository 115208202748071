import React from "react";
import clsx from "clsx";
import { Radio } from "@mui/material";



function RadioButton(props) {

  return (
    <Radio
      disableRipple
      color="default"
      {...props}
    />
  );
}

export default RadioButton;
