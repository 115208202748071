import React from "react";
import { Button, Grid } from "@mui/material";
//import { makeStyles } from "@material-ui/core/styles";
/*
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));
*/
function StatusTabs({ loadInvoiceByStatus, status }) {
  //const classesUI = useStyles();
  return (
    <Grid justify="flex-end" container spacing={2} //className={classesUI.root}
    >
      <Grid item>
        <Button
          onClick={() => loadInvoiceByStatus("paid")}
          variant={status === "paid" ? "contained" : "outlined"}
          color={status === "paid" ? "primary" : "secondary"}
        >
          PAGADOS
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => loadInvoiceByStatus("cancel")}
          variant={status === "cancel" ? "contained" : "outlined"}
          color={status === "paid" ? "secondary" : "primary"}
        >
          ANULADOS
        </Button>
      </Grid>
    </Grid>
  );
}

export default StatusTabs;
