import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

 const DialogForm = ({onAccept ,onClose, open, title, message, children}) => {
  return (
        <React.Fragment>
          <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {message}
              </DialogContentText>
              {children}
            </DialogContent>
            <DialogActions>
              <Button onClick={onAccept}>Aceptar</Button>
              <Button onClick={onClose}>Cerrar</Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
}

export default DialogForm;
