import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import styles from "./documents.module.scss";

export const Documents = ({
  checkDocumentItem,
  generalDoctorTitle,
  letterForAdmission,
  residencyLetterAcceptance,
  identificationDocument,
  medicalCollegeCardAndCertification,
  two2x2photos,
  inscription,
}) => {
  return (
    <fieldset className={styles.documentContainer}>
      <legend>Documentos</legend>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={letterForAdmission}
              onChange={checkDocumentItem}
              name="letterForAdmission"
              color="primary"
            />
          }
          label="Carta de solicitud para ingreso"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={residencyLetterAcceptance}
              onChange={checkDocumentItem}
              name="residencyLetterAcceptance"
              color="primary"
            />
          }
          label="Carta de promocion de año OH de aceptación de la residencia"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={medicalCollegeCardAndCertification}
              onChange={checkDocumentItem}
              name="medicalCollegeCardAndCertification"
              color="primary"
            />
          }
          label="Carta o carnet del CMD que confirme el numero de colegiatura"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={generalDoctorTitle}
              onChange={checkDocumentItem}
              name="generalDoctorTitle"
              color="primary"
            />
          }
          label="Copia del título de médico general"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={identificationDocument}
              onChange={checkDocumentItem}
              name="identificationDocument"
              color="primary"
            />
          }
          label="Copia de cédula"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={two2x2photos}
              onChange={checkDocumentItem}
              name="two2x2photos"
              color="primary"
            />
          }
          label="Dos fotos 2x2"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={inscription}
              onChange={checkDocumentItem}
              name="inscription"
              color="primary"
            />
          }
          label="Inscripción"
        />
      </FormGroup>
    </fieldset>
  );
}
