import React from "react";
import Select from "react-select";
import { TextField, InputAdornment, Fab, RadioGroup, FormControlLabel, Radio, } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NumberFormatInput from "../../../../components/NumberFormatInput/NumberFormatInput";
import { calculateAmount } from "../../../../utils/utils";
import styles from "./servicesForm.module.scss";

function ServicesForm({
  onAddService,
  validState,
  onDropDownChange,
  onChangeInput,
  isDisabled,
  isAddServiceDisabled,
  form,
  currencyRate,
  currencyType,
  selectedService,
  onCurrencyChange,
  showRate,
  onChangeCurrencyRate
  
}) {

  const transformAmount = calculateAmount(
    selectedService?.service?.currency,
    currencyType,
    currencyRate
  );
  
  return (
    <div className={styles.servicesContainer}>
      <div //className={classes.servicesContainerForm}
      >
        <label>Servicio</label>
        <Select
          name="service"
          className={`${styles.servicesDropdown} ${styles.service_drop_down} ${
            !!validState.service ? styles.service_drop_down_error : ""
          }`}
          classNamePrefix="service"
          options={form.services}
          onChange={onDropDownChange}
          isDisabled={isDisabled}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
        <TextField
          name="quantity"
          id="filled-basic"
          className={styles.textField}
          label="Cantidad"
          margin="normal"
          variant="filled"
          onChange={onChangeInput}
          value={form.quantity}
          error={!!validState.quantity}
          helperText={!!validState.quantity && validState.quantity}
          disabled={isDisabled}
        />

        <TextField
          name="discount"
          id="filled-basic"
          className={styles.textField}
          label="Descuento"
          margin="normal"
          variant="filled"
          onChange={onChangeInput}
          value={form.discount}
          error={!!validState.discount}
          helperText={!!validState.discount && validState.discount}
          disabled={isDisabled}
          InputProps={{
            inputComponent: NumberFormatInput,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <TextField
          name="price"
          className={styles.textField}
          label="Precio"
          margin="normal"
          variant="filled"
          value={transformAmount(form.price)}
          error={!!validState.price}
          helperText={!!validState.price && validState.price}
          disabled={isDisabled}
          InputProps={{ inputComponent: NumberFormatInput, readOnly: true }}
        />
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          className={styles.addBtn}
          onClick={onAddService}
          disabled={isAddServiceDisabled}
        >
          <AddIcon />
        </Fab>
      </div>
      <div className={styles.wrapper}>
        <TextField
          label="Notas"
          className={styles.textArea}
          multiline
          rows={5}
          value={form.notes}
          name="notes"
          variant="outlined"
          onChange={onChangeInput}
        />
        <div>
          <RadioGroup aria-label="moneda" value={currencyType} row>
            <FormControlLabel
              value="DOP"
              name="currencyType"
              control={<Radio />}
              label="DOP"
              onChange={onCurrencyChange}
              disabled={isDisabled}
            />
            <FormControlLabel
              value="USD"
              name="currencyType"
              control={<Radio />}
              label="USD"
              onChange={onCurrencyChange}
              disabled={isDisabled}
            />
            {showRate ? (
              <TextField
                name="currencyRate"
                label={`Tasa del ${currencyType === "USD" ? "dollar" : "peso"}`}
                margin="normal"
                variant="outlined"
                onChange={onChangeCurrencyRate}
                value={currencyRate}
                className={styles.rateText}
              />
            ) : null}
          </RadioGroup>
        </div>
      </div>
    </div>
  );
}

export default ServicesForm;
