import React from "react";
import {
  Button,
  CircularProgress,
  ButtonGroup,
  Tabs,
  Tab,
} from "@mui/material";
import styles from "./statuFilters.module.scss";


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function StatusTabs({ loadMembersByStatus, memberStatusDisplay, metadata, tabIndex, handleChangeTab }) {
 
  return (
    <div className={styles.filterContainer}>
      <div>
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            aria-label="tabs de tipos de miembros"
          >
            <Tab label="Miembros" {...a11yProps(0)}/>
            <Tab label="Aspirantes" {...a11yProps(1)}/>
          </Tabs>
      </div>
      <ButtonGroup>
        <Button
          onClick={() => loadMembersByStatus("active")}
          variant={memberStatusDisplay === "active" ? "contained" : "outlined"}
          color={memberStatusDisplay === "active" ? "primary" : "secondary"}
        >
          ACTIVOS<span className="btn_indicator">({metadata.totalActive})</span>
        </Button>

        <Button
          onClick={() => loadMembersByStatus("inactive")}
          variant={memberStatusDisplay === "active" ? "outlined" : "contained"}
          color={memberStatusDisplay === "active" ? "secondary" : "primary"}
        >
          INACTIVOS
          <span className="btn_indicator">({metadata.totalInactive})</span>
        </Button>

        <Button disabled>
          {metadata.loading ? (
            <CircularProgress />
          ) : (
            <span className="members_total">
              Total: {metadata.totalTableRow}
            </span>
          )}
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default StatusTabs;
