import React, { useReducer, useEffect, useState, useMemo } from "react";
import { Container, Box } from "@mui/material";
import { DialogWithButton } from "../../../components";
import { getCompanies } from "../../../services/companyAPI";
import { getMember } from "../../../services/memberAPI";
import { saveInvoice as postInvoice } from "../../../services/invoiceAPI";
import { getServicesByStatus } from "../../../services/servicesAPI";
import InvoiceOwner from "../components/InvoiceOwner/InvoiceOwner";
import FormSettings from "../../../components/paymentManagment/FormSettings/FormSettings";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper/SnackbarContentWrapper";
import ServicesForm from "../components/ServicesForm/ServicesForm";
import ServicesDetails from "../../../components/paymentManagment/ServicesDetails/ServicesDetails";
import MemberReference from "../../../components/paymentManagment/MemberReference/MemberReference";
import TitleBanner from "../../../components/paymentManagment/TitleBanner/TitleBanner";
import InvoicePrintPage from "../components/InvoicePrintPage/InvoicePrintPage";
import reducer from "./CompanyReducer";
import { useToastSetter } from "../../../context/ToastContext";
import styles from "./chargeForm.module.scss";

const initialState = {
  form: {
    _id: null,
    service: {
      label: "",
      value: "",
    },
    //services: [],
    quantity: 0,
    discount: 0,
    price: 0,
    voucherNumber: "",
    taxpayerRegistryNumber: "",
    paymentType: "cash",
    notes: "",
  },
  invoice: {
    services: [],
    referenceMembers: [],
    date: "",
    owner: {
      label: "",
      value: "",
    },
    ownerType: "member",
    applyItebis: false,
    currencyType: "",
    currencyRate: 1,
  },
  status: "",
  isFetchCompanies: false,
  isSaving: false,
  isPrint: false,
  httpMsg: {
    variant: "",
    msg: "",
  },
  invoiceType: 'full'
};

const initalValidState = {
  quantity: "",
  service: "",
  owner: "",
  price: "",
};

let invoiceForPrint = {};
function validReducer(state, action) {
  switch (action.type) {
    case "change": {
      return {
        ...state,
        [action.name]: action.value,
      };
    }
    case "reset_disc_quantity": {
      return {
        ...state,
        discount: "",
        quantity: "",
      };
    }
    default:
      return state;
  }
}
/*
const useStyle = makeStyles(() => ({
  containerTopRoot: { marginTop: 15, marginBottom: 15 },
  bodyContainerRoot: { marginTop: '2rem', padding: 10 }
}));
*/
function Company() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [validState, dispatchValid] = useReducer(
    validReducer,
    initalValidState
  );
  const [isSaving, setIsSaving] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const setToast = useToastSetter();

  function verifyServiceOwnership(owner) {
    let ownerKey = '';
    switch(owner) {
      case 'member':
        ownerKey = 'forMember';
      break;
      case 'company':
        ownerKey = 'forCompany';
      break;
      case 'person':
        ownerKey = 'forPerson';
      break;
      case 'aspiring':
        ownerKey = 'forAspiring';
      break;
      default:
        ownerKey = 'forMember';
    }

    for (let serv=0; serv < state.invoice.services.length; serv++) {
      const service = state.invoice.services[serv];
      if (!service.body[ownerKey]) {
        return false;
      }
    }

    return true;
  }

  function onInvoiceChange(name, value) {
    if (name === 'ownerType') {
      if (verifyServiceOwnership(value)) {
        dispatch({
          type: "change_invoice",
          name: 'owner',
          value: {
            label: '', 
            value: '',
          }
        });
        dispatch({
          type: "change_invoice",
          name,
          value
        });
      } else {
        setOpenDialog(true);
      }
    } else {
      dispatch({
        type: "change_invoice",
        name,
        value
      });
    }
  }

  async function handleSearchCompanies(inputValue, callback, ownerType) {
    try {
      //dispatch({ type: "companies_request" });
      if (ownerType === "company") {
        let { body: payload } = await getCompanies(inputValue);
        const companies = payload.data.map((company) => ({
          value: company._id,
          label: company.name,
          reference: company.registerNumber,
          address: company.address || ""
        }));
        //dispatch({ type: "companies_success" });
        callback(companies);
      } else if (ownerType === "member") {
        let { body: payload } = await getMember("all", 0, 50, inputValue);
        if (payload.data && payload.data.length) {
          let members = payload.data.map((member) => ({
            value: member._id,
            label: member.familyName + " " + member.names,
            reference: member.identifier,
            body: member
          }));
          callback(members);
        } else {
          callback([]);
        }
      } else {
        callback([]);
      }
    } catch (err) {
      dispatch({
        type: "companies_failure",
        payload: {
          msg: "Hubo un error al buscar la compañía.",
        },
      });
    }
  }

  function onDropDownCompanyChange(newValue, { name }) {
    const { value, label, ...rest } = newValue;
      dispatch({
        type: "change_invoice",
        name,
        value: {
          value: value,
          label: label,
          ...rest,
        },
      });
  }

  function handleAddReferenceMember(service, referenceMember) {
    const data = {
      service: service,
      member: referenceMember,
    };

    const reference = state.invoice.referenceMembers.find(
      (item) => item.service === service
    );
    if (reference) {
      const member = reference.members.find(
        (item) => item.value === referenceMember.value
      );
      if (!member) {
        dispatch({ type: "add_member_reference_service", payload: data });
      }
    } else {
      dispatch({ type: "add_member_reference", payload: data });
    }
  }

  function handleRemoveReferenceMember(service, referenceMember) {
    dispatch({
      type: "remove_member_reference_service",
      payload: {
        service: service,
        member: referenceMember,
      },
    });
  }

  function onDropDownServiceChange(newValue, { name }) {
    if (!!validState.service) {
      dispatchValid({ type: "change", name: "service", value: "" });
    }
    dispatch({
      type: "change_form_value",
      name: "price",
      value: newValue.service.price,
    });
    dispatch({
      type: "change_form_value",
      name: name,
      value: {
        value: newValue.value,
        label: newValue.label,
        service: newValue.service,
      },
    });
  }

  function handleOnNewInvoice() {
    dispatch({
      type: "set_new_invoice",
      payload: {
        form: {
          ...state.form,
          quantity: 0,
          price: 0,
          service: {
            label: "",
            value: "",
          },
          notes: "",
          isPrint: false,
          paymentType: "cash",
        },
        invoice: {
          ...initialState.invoice,
          date: new Date(Date.now()),
        },
        isFetchCompanies: false,
        isSaving: false,
        isPrint: false,
        httpMsg: {
          variant: "",
          msg: "",
        },
        status: "new",
      },
    });
  }

  function removeServices(id) {
    dispatch({ type: "remove_invoice_services", value: id });
  }

  function decreaseServiceQuantity(id) {
    let service = state.invoice.services.find(
      (service) => service.value === id
    );
    
    if (service.quantity <= 1) {
      removeServices(id);
    } else {
      service.quantity = +service.quantity - 1;
      dispatch({ type: "modified_invoice_services", service });
    }
  }

  function onChangeInput({ target: { value, name } }) {
    dispatch({ type: "change_form_value", name, value });
    if (name === "discount" || name === "quantity") {
      dispatchValid({
        type: "reset_disc_quantity",
      });
    }
  }

  function onAddService() {
    /*if (!Object.keys(state.form.service).length) {
        dispatchValid({
          type: "change",
          name: "service",
          value: "Seleccione un servicio",
        });
      } else*/ if (!state.form.quantity || !state.form.quantity < 0) {
      dispatchValid({
        type: "change",
        name: "quantity",
        value: "El valor debe ser mayor que cero",
      });
    } else if (state.form.discount > 100) {
      dispatchValid({
        type: "change",
        name: "discount",
        value: `El valor debe ser menor que 100`,
      });
    } else {
      let service = state.invoice.services.find(
        (service) => service.value === state.form.service.value
      );
      if (service) {
        service.quantity = +service.quantity + +state.form.quantity;
        service.discount = state.form.discount;
        dispatch({ type: "modified_invoice_services", service });
      } else {
        dispatch({
          type: "change_invoice",
          name: "services",
          value: {
            value: state.form.service.value,
            label: state.form.service.label,
            quantity: state.form.quantity,
            discount: state.form.discount,
            price: state.form.service.service.price,
            body: state.form.service.service,
          },
        });
      }
      dispatch({ type: "reset_quantity" });
    }
  }

  async function onSubmit(invoiceTypeOption) {
    try {
      setIsSaving(true);
      if (!state.invoice.services.length || 
        (!state.invoice.owner.label || !state.invoice.owner.value)) {
        dispatchValid({
          type: "change",
          name: "service",
          value: "Campos requeridos",
        });
        dispatchValid({
          type: "change",
          name: "owner",
          value: "Campos requeridos",
        });
        setIsSaving(false);
        return;
      }
      let totalAmountService = 0;
      let totalAmountDiscount = 0;
      const data = {
        ...state.invoice,
        services: [],
        referenceMembers: [],
      };

      for (let x = 0; x < state.invoice.services.length; x++) {
        let service = state.invoice.services[x];
    
        data.services.push({
          description: service.label,
          isMembership: service.body.isMembership,
          price: service.price,
          quantity: service.quantity,
          discount: service.discount,
          accountNumber: service.body.accountNumber,
          id: service.value,
          currency: service.body.currency,
          excludeTax: service.body.excludeTax,
        });
        let total = service.quantity * service.price
        totalAmountService += total;
        
        totalAmountDiscount += (total * parseInt(service.discount))/100;
      }

      for (let x = 0; x < state.invoice.referenceMembers.length; x++) {
        data.referenceMembers.push({
          service: {
            id: state.invoice.referenceMembers[x].service.body._id,
            description: state.invoice.referenceMembers[x].service.label,
          },
          members: state.invoice.referenceMembers[x].members.map((item) => ({
            fullName: item.label,
            identifier: item.value,
          })),
        });
      }

      data.total = totalAmountService - totalAmountDiscount;
      data.totalDiscount = totalAmountDiscount;
      data.paymentType = state.form.paymentType;
      data.taxpayerRegistryNumber = state.invoice.ownerType === "company" ? state.invoice.owner?.reference : state.form.taxpayerRegistryNumber;
     
      data.invoiceType = invoiceTypeOption;
      
      let reference = '';
      switch(state.invoice.ownerType) {
        case 'person':
          reference = state.invoice.owner.value;
          break;
        default:
          reference = state.invoice.owner.reference;
          break;
      }

      data.owner = {
        name: state.invoice.owner.label,
        _id: state.invoice.ownerType !== 'person' ? state.invoice.owner.value : null,
        reference: reference,
      };
      data.ownerType = state.invoice.ownerType;
      data.voucherNumber = state.form.voucherNumber;
      data.status = "paid";
      data.notes = state.form.notes;

     const payload = await postInvoice(data);
      
      if (payload.body.success) {
        dispatch({
          type: "save_invoice",
        });
        dispatch({
          type: "change_invoice",
          name: "id",
          value: payload.body.data.id,
        });

        if (payload.body.data?.taxReceiptNumber) {
          dispatch({
            type: "change_invoice",
            name: "taxReceiptNumber",
            value: payload.body.data.taxReceiptNumber,
          });
        }

        if (payload.body.data?.taxNumberInformation) {
          dispatch({
            type: "change_invoice",
            name: "taxNumberInformation",
            value: payload.body.data.taxNumberInformation,
          });
        }

        printInvoice(payload.body.data.id, payload.body.data?.taxReceiptNumber, invoiceTypeOption, payload.body.data?.taxNumberInformation);
        setIsSaving(false);
      }
      
    } catch (err) {
      setToast({
        open: true,
        message: err.message,
        variant: "error",
      });
      setIsSaving(false);
    }
  }

  function printInvoice(id, taxReceiptNumber, invoiceTypeOption, taxNumberInformation) {
    if (state.invoice.services.length && !!state.invoice.owner.label) {
      let servicesFormat = [];
      let referenceMembers = [];

      state.invoice.services.forEach((service) => {
        servicesFormat.push({
          description: service.label,
          isMembership: service.body.isMembership,
          price: service.price,
          quantity: service.quantity,
          discount: service.discount,
          currency: service.body.currency,
          excludeTax: service.body.excludeTax
        });
      });

      for (let x = 0; x < state.invoice.referenceMembers.length; x++) {
        referenceMembers.push({
          service: {
            id: state.invoice.referenceMembers[x].service.body._id,
            description: state.invoice.referenceMembers[x].service.label,
          },
          members: state.invoice.referenceMembers[x].members.map((item) => ({
            fullName: item.label,
            identifier: item?.body?.identifier || item.value,
          })),
        });
      }
      
      invoiceForPrint = {
        ...state.invoice,
        client: {
          name: state.invoice.owner.label,
          identifier: state.invoice.owner?.reference  || state.invoice.owner.value,
          address: state.invoice.owner?.address || '',
        },
        referenceMembers: referenceMembers,
        services: servicesFormat,
        notes: state.form.notes,
        paymentType: state.form.paymentType,
        id: id ? id : state.invoice.id ? state.invoice.id : "",
        taxReceiptNumber: taxReceiptNumber ? taxReceiptNumber : state.invoice.taxReceiptNumber ? state.invoice.taxReceiptNumber : "",
        voucherNumber: state.form.voucherNumber,
        taxpayerRegistryNumber: state.invoice.ownerType === "company" ? state.invoice.owner?.reference : state.form.taxpayerRegistryNumber,
        invoiceType: invoiceTypeOption,
        taxNumberInformation: taxReceiptNumber ? taxNumberInformation : state.invoice.taxNumberInformation
      };
      
      dispatch({
        type: "change_prop",
        name: "isPrint",
        value: true,
      });
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }

  function onApplyITEBIS(event) {
    const { name, checked } = event.target;
    dispatch({ type: "change_invoice", name, value: checked });
  }

  function onCurrencyChange(event) {
    const { name, value } = event.target;
    dispatch({ type: "change_invoice", name, value });
  }

  function onChangeCurrencyRate(event) {
    const { name, value } = event.target;
    dispatch({ type: "change_invoice", name, value });
  }

  
 const hasBothCurrency = useMemo(() => {
  let lastCurrency;
  
  if (state.invoice.services.length &&
     state.invoice.currencyType === ''
     // || state.invoice.services[0]?.body.currency !== state.invoice.currencyType
     ) {
    dispatch({ 
      type: "change_invoice", 
      name: 'currencyType', 
      value: state.invoice.services[0]?.body.currency
    });
  }
  
  for (let x = 0; x < state.invoice.services.length; x++) {
    if (lastCurrency 
      && lastCurrency !== state.invoice.services[x].body.currency) {  
        return true;
    }
    lastCurrency = state.invoice.services[x].body.currency;
  }
  return false
 },[state.invoice.services])

  useEffect(() => {
    (async () => {
      try {
        let { body: servicesFetched } = await getServicesByStatus(
          "active",
          ""
        );
        if (servicesFetched.success) {
          let services = [];
          servicesFetched.data.forEach((service) => {
            services[services.length] = {
              value: service.id,
              label: service.description,
              service,
            };
          });
          dispatch({ type: "fetched_services", payload: services });
        }
      } catch (e) {
        console.log("EXPORT***", e);
      }
    })();
  }, []);

  return (
    <>
      <Box displayPrint="none">
        <Container //classes={{root: classes.bodyContainerRoot}}
        >
          <DialogWithButton
            onClose={() => {setOpenDialog(false);}}
            open={openDialog}
            title="No se puede cambiar el tipo de titular"
            message={"Existen servicio(s) que no aplican para el tipo de titular que quiere cambiar. Favor de quitar los servicio(s) agregado que no aplican."}
          />
          {!!state.httpMsg.msg && (
            <SnackbarContentWrapper
              variant={state.httpMsg.variant}
              message={state.httpMsg.msg}
            />
          )}
          {
            hasBothCurrency ? <div>
              <p>La factura no puede presentar servicios con diferentes monedas.</p>
            </div> : null
          }
          <FormSettings
            newInvoice={handleOnNewInvoice}
            saveInvoice={onSubmit}
            status={state.status}
            formId={state.form._id}
            isSaving={state.isSaving}
            printInvoice={printInvoice}
           // canPrint={state.status === "save"}
            isDisabled={state.status === "" || state.status === "save"}
            paymentType={state.form.paymentType}
            title="Factura"
          />
          <TitleBanner
            onChangeInput={onChangeInput}
            taxpayerRegistryNumber={ state.invoice.ownerType === "company" ? state.invoice.owner?.reference : state.form.taxpayerRegistryNumber}
            onApplyITEBIS={onApplyITEBIS}
            applyItebis={state.invoice.applyItebis}
            isDisabled={state.status === "" || state.status === "save"}
            currencyType={state.invoice.currencyType ? 
              state.invoice.currencyType : 
              state.invoice.services.length ? 
              state.invoice.services[0].body.currency : 
              ''}
            showRate={state.invoice.currencyType !== (state.invoice.services[0]?.body.currency || '')}
            currencyRate={state.invoice.currencyRate}
            onCurrencyChange={onCurrencyChange}
            onChangeCurrencyRate={onChangeCurrencyRate}
            paymentType={state.form.paymentType}
            ownerType={state.invoice.ownerType || 'member'}
          />

          <InvoiceOwner
            fetchItems={handleSearchCompanies}
            onDropDownChange={onDropDownCompanyChange}
            value={state.invoice.owner}
            onInvoiceChange={onInvoiceChange}
            ownerType={state.invoice.ownerType || 'member'}
            personName={state.invoice.owner.label}
            personReference={state.invoice.owner.value}
            isDisabled={
              state.status === "" ||
              state.status === "save" ||
              isSaving
            }
            validState={validState}
            verifyServiceOwnership={verifyServiceOwnership}
          />

          <ServicesForm
            form={state.form}
            onChangeInput={onChangeInput}
            onDropDownChange={onDropDownServiceChange}
            isDisabled={state.status === "" || state.status === "save"}
            isAddServiceDisabled={
              state.form.service && state.form.service.value === ""
            }
            validState={validState}
            onAddService={onAddService}
            currencyRate={state.invoice.currencyRate}
            currencyType={state.invoice.currencyType}
            //firstService={state.invoice.services[0]?.body.currency}
            selectedService={state.form?.service}

            showRate={state.invoice.currencyType !== (state.invoice.services[0]?.body.currency || '')}
            onCurrencyChange={onCurrencyChange}
            onChangeCurrencyRate={onChangeCurrencyRate}
          />

          <ServicesDetails
            services={state.invoice.services}
            removeServices={removeServices}
            decreaseServiceQuantity={decreaseServiceQuantity}
            isDisabled={false}
            applyItebis={state.invoice.applyItebis}
            currencyRate={state.invoice.currencyRate}
            currencyType={state.invoice.currencyType}
          />
          <MemberReference
            addReferenceMember={handleAddReferenceMember}
            removeReferenceMember={handleRemoveReferenceMember}
            services={state.invoice.services}
            references={state.invoice.referenceMembers}
          />
        </Container>
      </Box>
      {state.isPrint && Object.keys(invoiceForPrint).length ?  (
        <InvoicePrintPage {...invoiceForPrint} isPrint={state.isPrint} isHalfPaper={false} />
      ) : null}
    </>
  );
}

export default Company;
