import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  //IconButton,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { amber, green } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
//import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { useToastSetter, useToast } from "../../context/ToastContext"

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

MySnackbarContentWrapper.propTypes = {
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]),
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
};

function MySnackbarContentWrapper({
  className,
  message,
  onClose,
  variant,
  onSetHttp,
  show = true,
}) {
  //const [hasShow, setShow] = React.useState(show);
  const setToast = useToastSetter();
  const toast = useToast();
  const Icon = variantIcon[toast.variant];

  useEffect(() => {
    setTimeout(() => {
      setToast({
        open: false,
        message: ''
      });
    }, 9000);
  }, [toast.open]);

  return (
    <Snackbar 
      open={toast.open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
      //autoHideDuration={6000} 
    //onClose={onClose}
    >
      <Alert //onClose={onClose} 
      severity={toast.variant} sx={{ width: "100%" }}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
}

/*<SnackbarContent
      className={classes[variant]}
      aria-describedby="http message"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classes["icon"] + " " + classes.iconVariant} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />*/
export default MySnackbarContentWrapper;
