import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  TextField,
  Breadcrumbs,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import clsx from "clsx";
import { useNavigate, Link, useParams } from "react-router-dom";
import { TextMaskPhone } from "../../components/MaskInput/MaskInput";
import API from "../../config/api";
import { validateForm } from "../../utils/utils";
import { useToastSetter } from "../../context/ToastContext";

import styles from "./commercialProvider.module.scss";

const formInitalState = {
  id: "",
  fullName: "",
  registerNumber: "",
  phoneNumber: "",
  email: "",
  isActive: true,
};

const validFormInital = {
  fullName: true,
  registerNumber: true,
  phoneNumber: true,
  email: true,
};

function Form() {
  const [form, setForm] = useState(formInitalState);
  const [validForm, setValidForm] = useState(validFormInital);
  
  const navigate = useNavigate();
  const { id } = useParams();
  const  setToast  = useToastSetter();

  function onChangeFormInputs({ target: { name, value } }) {
    setForm({
      ...form,
      [name]: value,
    });
  }

  const onHandleChangeCheckbox = ({ target: { name, checked } }) => {
    setForm({
      ...form,
      [name]: checked,
    });
  };

  async function onSaveCompany() {
    try {
      const inputs = ["fullName", "registerNumber", "phoneNumber"];
      let formResultValidation = validateForm(form, inputs);
      let payload = {};
      if (formResultValidation.isValid) {
        if (form._id) {
          let { body } = await API.put(`commercial_supplier/${form._id}`,form);
          payload = body;
        } else {
          let { body } = await API.post('commercial_supplier',form);
          payload = body;
        }
        if (payload.success) {
          setToast({
            open: true,
            message: `El proveedor se ${
              form.id ? "actualizado" : "guardo"
            } con éxito!`,
            variant: "success",
          });
        }
        
            window.setTimeout(() => {
              navigate("/proveedor");
            }, 990);
            
      } else {
        setToast({
          open: true,
          message: "Hubo un error al intentar guardar.",
          variant: "erro",
        });
        setValidForm(formResultValidation.validatedState);
      }
    } catch(error) {
      setToast({
        open: true,
        message: "Hubo un error al llenar el formulario.",
        variant: "warning",
      });
    }
  }

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const payload = await API.get(`commercial_supplier/${id}`);
          setForm(payload);
        } catch(error) {
          setToast({
            open: true,
            message: 'No se pudo encontrar el proveedor',
            variant: "error",
          });
        }
      }
    })();
  }, [id]);

  return (
    <Container maxWidth="sm">
      <Paper>
        <Breadcrumbs>
          <Link color="inherit" to="/">
            Inicio
          </Link>
          <Typography color="textPrimary">
            Formulario de proveedor comercial
          </Typography>
        </Breadcrumbs>
      </Paper>
      <div className={styles.container}>
        <TextField
          id="fullName"
          label="Nombre completo"
          value={form.fullName}
          name="fullName"
          onChange={onChangeFormInputs}
          className={styles.textField}
          error={!validForm.fullName}
        />
        <TextField
            value={form.phoneNumber}
            onChange={onChangeFormInputs}
            name="phoneNumber"
            id="phoneNumber-input"
            InputProps={{
              inputComponent: TextMaskPhone
            }}
            label="Teléfono"
            fullWidth
            error={!validForm.phoneNumber}
            required
          />
        <TextField
          id="email"
          label="Email"
          value={form.email}
          name="email"
          onChange={onChangeFormInputs}
          className={styles.textField}
          error={!validForm.email}
          helperText={!validForm.email && "Campo en blanco o email inválido"}
        />
        <TextField
          id="registerNumber"
          label="RNC"
          value={form.registerNumber}
          name="registerNumber"
          onChange={onChangeFormInputs}
          className={styles.textField}
          error={!validForm.registerNumber}
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={form.isActive}
              onChange={onHandleChangeCheckbox}
            />
          }
          label="Activo"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onSaveCompany}
          //className={classes.buttonSubmit}
        >
          {form.id || id ? "Actualizar" : "Guardar"}
        </Button>
      </div>
    </Container>
  );
}

export default Form;
